<div class="__controls">
  <span class="__control  __control--close" tooltip="Sorry, not a real terminal"></span>
  <span class="__control  __control--minimize" tooltip="Sorry, not a real terminal"></span>
  <span class="__control  __control--full" tooltip="Sorry, not a real terminal"></span>
</div>

<div class="__content">
  <zui-docs-text>

    {{ mode | json }}
    <ng-container *ngIf="mode === 'push'">hey</ng-container>

    <ng-container *ngIf="mode === 'push'">

      <span class="__comment">
        <pre># install zcli, see <a data-zui-docs="cli/installation.html">docs</a> for more install options</pre>
      </span>
      <span class="__command">
        <pre><span class="__arrow">➜</span><span class="__tilda">~</span> <zef-copy-to-clipboard [copyData]="'npm i -g @zerops/zcli'">npm i -g &#64;zerops/zcli</zef-copy-to-clipboard></pre>
      </span>

      <span class="__comment">
        <pre># login using token that can be obtained</pre>
        <pre># at <a target="_blank" [href]="accessTokenSettingsUrl">{{ accessTokenSettingsUrl }}</a></pre>
        <pre># (needs to be done only once)</pre>
      </span>

      <span class="__command">
        <pre><span class="__arrow">➜</span><span class="__tilda">~</span> <zef-copy-to-clipboard [copyData]="'zcli login' + (region && region !== 'zerops' ? (' --region=' + region) : '')">zcli login <span class="__placeholder">PLACE_TOKEN_HERE</span> {{ region && region !== 'zerops' ? ('--region=' + region) : '' }} </zef-copy-to-clipboard></pre>
      </span>

      <span class="__comment">
        <pre># navigate to the root of your project</pre>
        <pre># (where zerops.yml is) and use <a data-zui-docs="cli/available-commands.html#push-project-name-service-name">push</a></pre>
        <pre># command to trigger the pipeline</pre>
      </span>

      <span class="__command  is-last">
        <pre><span class="__arrow">➜</span><span class="__tilda">~</span> cd ./my-project</pre>
        <pre><span class="__arrow">➜</span><span class="__tilda">my-project</span> <zef-copy-to-clipboard [copyData]="'zcli push'">zcli push</zef-copy-to-clipboard><span class="__cursor"></span></pre>
      </span>

    </ng-container>

    <ng-container *ngIf="mode === 'vpn'">
      <span class="__comment">
        <pre># install zcli, see <a data-zui-docs="cli/installation.html">docs</a> for more install options</pre>
      </span>
      <span class="__command">
        <pre><span class="__arrow">➜</span><span class="__tilda">~</span> <zef-copy-to-clipboard [copyData]="'npm i -g @zerops/zcli'">npm i -g &#64;zerops/zcli</zef-copy-to-clipboard></pre>
      </span>

      <span class="__comment">
        <pre># login using token that can be obtained</pre>
        <pre># at <a target="_blank" [href]="accessTokenSettingsUrl">{{ accessTokenSettingsUrl }}</a></pre>
        <pre># (needs to be done only once)</pre>
      </span>

      <span class="__command">
        <pre><span class="__arrow">➜</span><span class="__tilda">~</span> <zef-copy-to-clipboard [copyData]="'zcli login' + (region && region !== 'zerops' ? (' --region=' + region) : '')">zcli login <span class="__placeholder">PLACE_TOKEN_HERE</span> {{ region && region !== 'zerops' ? ('--region=' + region) : '' }} </zef-copy-to-clipboard></pre>
      </span>

      <span class="__comment">
        <pre># start Zerops vpn to access services locally under</pre>
        <pre># their <a data-zui-docs="routing/routing-between-project-services.html">hostname and port</a> as if you were part of</pre>
        <pre># the projects private network</pre>
      </span>

      <span class="__command">
        <pre><span class="__arrow">➜</span><span class="__tilda">~</span> <zef-copy-to-clipboard [copyData]="'zcli vpn start'">zcli vpn up</zef-copy-to-clipboard><span class="__cursor"></span></pre>
      </span>

    </ng-container>

  </zui-docs-text>
</div>
