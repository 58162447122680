<div
  class="__wrap"
  [class.is-queued]="type === 'QUEUED'"
  [class.is-finished]="type === 'FINISHED'"
  [class.is-failed]="type === 'FAILED'">

  <zui-process-step-state
    *ngIf="!hideIcon"
    class="__icon"
    [state]="iconMap[type]">
  </zui-process-step-state>

  <span
    class="__time"
    [class.has-icon]="!hideIcon"
    *ngIf="!hideTime">

    <ng-container *ngIf="type === 'RUNNING' || type === 'TIMER'">
      <zui-duration [useShorthand]="useShorthand" [start]="start"></zui-duration><ng-template [ngIf]="!hideText">&nbsp;elapsed</ng-template>
    </ng-container>

    <ng-container *ngIf="type === 'FINISHED'">
      <ng-template [ngIf]="!hideText">Finished in&nbsp;</ng-template><zui-duration [useShorthand]="useShorthand" [start]="start" [end]="end"></zui-duration>
    </ng-container>

    <ng-container *ngIf="type === 'FAILED'">
      <ng-template [ngIf]="!hideText">Failed after&nbsp;</ng-template><zui-duration [useShorthand]="useShorthand" [start]="start" [end]="end"></zui-duration>
    </ng-container>

    <ng-container *ngIf="type === 'QUEUED'">
      <strong>in queue</strong>
    </ng-container>

  </span>

</div>
