import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FormsModule } from '@angular/forms';
import { ZefFormsModule } from '@zerops/zef/forms';
import { ZuiMaterialFormStackModule } from '@zerops/zui/material-form-stack';
import { MonacoEditorModule } from  'ngx-monaco-editor-v2';
import { CodeFieldComponent } from './code-field.component';

@NgModule({
  declarations: [ CodeFieldComponent ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ZefFormsModule,
    ZuiMaterialFormStackModule,
    MonacoEditorModule,
    FormsModule
  ],
  exports: [ CodeFieldComponent ]
})
export class CodeFieldModule {

}
