import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule  } from '@angular/material/expansion';
import { ZuiPipelineDescModule } from '@zerops/zui/pipeline-desc';
// import { ZlogModule } from '@zerops/zerops/feature/zlog';
import { ZuiDeployStepModule } from '@zerops/zui/build-state-steps/modules/deploy-step';
import {
  ZuiBuildStateStepsModule,
  ZuiInitBuildStepModule,
  ZuiInitPrepareStepModule,
  ZuiRunBuildStepModule,
  ZuiRunPrepareStepModule
} from '@zerops/zui/build-state-steps';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZuiDurationModule } from '@zerops/zui/duration';
import { ZuiThreeDotsModule } from '@zerops/zui/three-dots';
import { ZefAvatarModule } from '@zerops/zef/avatar';
import { PipelineDetailFeature } from './pipeline-detail.feature';
import { MatIconModule } from '@angular/material/icon';
import { TrlogModule } from '../trlog';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ PipelineDetailFeature ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    RouterModule,
    MatIconModule,
    ZuiPipelineDescModule,
    ZuiInitBuildStepModule,
    ZuiRunBuildStepModule,
    ZuiInitPrepareStepModule,
    ZuiRunPrepareStepModule,
    ZuiDeployStepModule,
    // ZlogModule,
    ZuiSeparatorComponent,
    ZuiDurationModule,
    ZuiBuildStateStepsModule,
    ZuiThreeDotsModule,
    ZefAvatarModule,
    TrlogModule
  ],
  exports: [ PipelineDetailFeature ]
})
export class PipelineDetailModule { }
