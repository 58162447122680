<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxWidth: '1300px', width: '100%' }"
  (closeTrigger)="onClose$.next()">
  <div class="__content">

    <div
      class="__wrap"
      [class.is-pipeline]="defaultMode === 'PIPELINE'"
      *ngIf="!!state.appVersion">

      <z-pipeline-detail
        *ngIf="state.open"
        [maxWidthDetail]="'750px'"
        [maxWidthLogs]="'1200px'"
        class="__pipeline-detail"
        [baseKey]="key"
        [mode]="defaultMode"
        (modeChanged)="defaultMode = $event"
        [appVersion]="state.appVersion"
        [pipelineErrors]="state.pipelineErrors"
        (linkClicked)="onClose$.next()">
      </z-pipeline-detail>

    </div>

    <button
      class="__close-button"
      type="button"
      (click)="onClose$.next()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zef-dialog>
