import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FEATURE_NAME } from './common-user-service-stack-form.constant';
import { ZefTranslationsService } from '@zerops/zef/translations';

const en = {
  hostnameTitle: `Hostname`,
  hostnameDesc: `Select a short, URL-friendly name, like 'app', 'cache','qui' etc. Other services will be able to access this service using this hostname and the internal port, i.e., gui:3000. You can also access the services in the same way if using zcli VPN.`,
  internalPortsTitle: `Internal Ports`,
  internalPortsDesc: `The port(s) and protocol your application will be running on, i.e. http on 3000. Zerops will set up a private network inside the project allowing all services to see each other under their hostname:port.`,
  hostnameLabel: 'Hostname',
  hostnamePlaceholder: 'Use anything URL-friendly..',
  envVariablesTitle: 'Secret variables',
  envVariablesDesc: `Add environment variables with sensitive data, such as password, tokens, salts, certificates etc. These will be securely saved inside Zerops and added to your runtime service upon start. You can reference other secret variables, or other types of Zerops environment variables (generated, from zerops.yml file) even those that do not exist yet. You can also all other variables from other services inside the project.`,
  scalingDesc: `Zerops will scale the Node.js service <strong>horizontally</strong> by adding and removing containers and <strong>vertically</strong> by
  increasing and decreasing each container's resources, depending on the current load and the range you can fine tune to meet the exact needs of the environment for which you plan to run the service.`,
  saveVariable: 'Save the variable',
  addVariable: 'Add new variable',
  copyKeyTooltip: 'Click to copy the key name to clipboard'
};

export type CommonUserServiceStackFormTranslations = typeof en;

@NgModule({ exports: [ TranslocoModule ] })
export class CommonUserServiceStackFormTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
