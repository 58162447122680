import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyRippleModule as MatRippleModule } from '@angular/material/legacy-core';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { ProgressSpinnerModule } from '@zerops/zui/progress-spinner';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZefFuseModule } from '@zerops/zef/fuse';
import { ServiceStackTileModule } from '@zerops/zui/service-stack-tile';
import { ServiceStackTypeFieldComponent } from './service-stack-type-field.component';
import { ServiceStackTypeFieldTranslationsModule } from './service-stack-type-field.translations';

@NgModule({
  declarations: [ ServiceStackTypeFieldComponent ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatRippleModule,
    MaterialStackModule,
    ServiceStackTypeFieldTranslationsModule,
    ProgressSpinnerModule,
    SatPopoverModule,
    ServiceStackTileModule,
    ZefFuseModule
  ],
  exports: [ ServiceStackTypeFieldComponent ]
})
export class ServiceStackTypeFieldModule {

}
