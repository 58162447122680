import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { interval, merge, of, timer } from 'rxjs';
import startsWith from 'lodash-es/startsWith';
import {
  differenceInMilliseconds,
  addHours,
  startOfHour
} from 'date-fns/esm';
import {
  onWebsocketMessageDispatchUpdateEntities,
  onWebsocketMessageDispatchAddRemoveEntities
} from '@zerops/zef/entities';
import { UserEntity } from '@zerops/zerops/core/user-base';
import {
  TransactionGroupBy,
  TransactionTimeGroupBy,
  TRANSACTION_GROUP_RANGE
} from '@zerops/models/transaction-debit';
import { AppState } from '@zerops/zerops/app';
import { zefRemoveProgress } from '@zerops/zef/progress';
import { zefRemoveError } from '@zerops/zef/errors';
import { extractBetween } from '@zerops/zef/core';
import { zefLogout } from '@zerops/zef/auth';
import { zefWebsocketMessage } from '@zerops/zef/websocket';
import { TransactionDebitEntity } from './transaction-debit.entity';
import { TransactionDebitBaseApi } from './transaction-debit-base.api';
import {
  periodCost,
  periodCostFail,
  periodCostSuccess,
  trackTransactionDebitGroupItem,
  transactionDebitGroup,
  transactionDebitGroupCancel,
  transactionDebitGroupFail,
  transactionDebitGroupSuccess,
  transactionDebitGroupUpdate
} from './transaction-debit-base.action';
import { FEATURE_NAME } from './transaction-debit-base.constant';
import { selectTrackedItems, selectTransactionDebitBaseGroupedData } from './transaction-debit-base.selector';
import { trasactionGroupExists } from './transaction-debit-base.utils';

@Injectable()
export class TransactionDebitBaseEffect {

  private _activeClientId$ = this._userEntity.activeClientId$.pipe(filter((d) => !!d));

  private _hourlyTimer$ = timer(
    // the next full hour
    differenceInMilliseconds(startOfHour(addHours(new Date(), 1)), new Date()) + 500,
    // every hour
    60 * 60 * 1000
  );

  private _onTransactionDebitGroupTrackItems$ = createEffect(() => this._actions$.pipe(
    ofType(transactionDebitGroup),
    filter((a) => !!a.data?.groupRange?.hasCurrent && !a.data.isPeriodUpdate),
    map((a) => trackTransactionDebitGroupItem({ data: a }))
  ));

  private _setupAddRemoveMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this._transactionDebitEntity)
  ));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._transactionDebitEntity)
  ));

  private _onTransactionDebitGroup$ = createEffect(() => this._actions$.pipe(
    ofType(transactionDebitGroup),
    withLatestFrom(this._store.pipe(select(selectTransactionDebitBaseGroupedData))),
    mergeMap(([ action, transactions ]) => {
      const exists = trasactionGroupExists(action.data, transactions);

      if (exists && !action.data.isPeriodUpdate) { return of(transactionDebitGroupCancel()); }

      return this._activeClientId$.pipe(
        switchMap((clientId) => this._api
          .groupSearch$(
            clientId,
            action.data.groupBy,
            action.data.groupRange.timeGroupBy,
            action.data.groupRange.limit,
            action.data.projectId,
            action.data.serviceId,
            action.data.groupRange.range,
            action.data.groupRange.key
          )
          .pipe(
            map((response) => transactionDebitGroupSuccess({
              ...response,
              timeGroupBy: action.data.groupRange.timeGroupBy,
              groupBy: action.data.groupBy,
              limit: action.data.groupRange.limit,
              from: action.data.groupRange.range.from,
              till: action.data.groupRange.range.to,
              key: action.data.groupRange.key,
              projectId: action.data.projectId,
              serviceId: action.data.serviceId
            }, action)),
            catchError((err) => of(transactionDebitGroupFail(err, action)))
          )
        )
      );
    })
  ));

  private _onTransactionDebitGroupCancel$ = createEffect(() => this._actions$.pipe(
    ofType(transactionDebitGroupCancel),
    delay(0),
    mergeMap(() => [
      zefRemoveError(transactionDebitGroup.type),
      zefRemoveProgress(transactionDebitGroup.type)
    ])
  ))

  private _onDebitGroupUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(zefWebsocketMessage),
    filter((action) => action.message && startsWith(action.message.subscriptionName, `${FEATURE_NAME}_transaction-group`)),
    map((action) => ({ d: action.message.data, subName: action.message.subscriptionName })),
    map(({ d, subName }) => transactionDebitGroupUpdate({
      items: d?.update || [],
      timeGroupBy: d?.timeGroupBy,
      groupBy: d?.groupBy,
      limit: d?.limit,
      key: extractBetween('##', '##')(subName)[0],
      projectId: d?.projectId,
      serviceId: d?.serviceId
    }))
  ));

  // load default range (currently last24h)
  // for each level (project, service, metric)
  // for all projects / services
  private _onClientActiveIdBaseServiceMetricGroup$ = createEffect(() => this._activeClientId$.pipe(
    map(() => transactionDebitGroup({
      groupBy: TransactionGroupBy.Metric,
      groupRange: TRANSACTION_GROUP_RANGE.last24h
    }))
  ));

  private _onClientActiveIdBaseProjectServiceGroup$ = createEffect(() => this._activeClientId$.pipe(
    map(() => transactionDebitGroup({
      groupBy: TransactionGroupBy.Service,
      groupRange: TRANSACTION_GROUP_RANGE.last24h
    }))
  ));

  private _onClientActiveIdBaseClientProjectGroup$ = createEffect(() => this._activeClientId$.pipe(
    map(() => transactionDebitGroup({
      groupBy: TransactionGroupBy.Project,
      groupRange: TRANSACTION_GROUP_RANGE.last24h
    }))
  ));

  // periodically load period cost once clientId becomes available
  private _onClientActiveIdStartPeriodCostLoadTimer$ = createEffect(() => this._activeClientId$.pipe(
    switchMap((clientId) => merge(
      of(undefined),
      interval(60 * 1000).pipe(take(10)),
      this._hourlyTimer$.pipe(
        switchMap(() => interval(60 * 1000).pipe(take(10)))
      )
    ).pipe(
      takeUntil(this._actions$.pipe(ofType(zefLogout))),
      map(() => periodCost(clientId)),
    ))
  ));

  private _onClientActiveIdStartDebitGroupUpdateTimer$ = createEffect(() => this._activeClientId$.pipe(
    switchMap(() => this._hourlyTimer$),
    switchMap(() => this._store.pipe(
      select(selectTrackedItems),
      take(1),
      filter((d) => !!d?.length),
      map((d) => {
        const r = d.reduce((obj, itm) => {
          const currentRange = itm.data.groupRange.getRange();
          const timeGroupBy = itm.data.groupRange.timeGroupBy;

          // TODO: optimize to run on days only if there's a new day
          // TODO: handle months
          if (timeGroupBy === TransactionTimeGroupBy.Hours
            || timeGroupBy === TransactionTimeGroupBy.Days) {
            obj.push(({
              ...itm,
              data: {
                ...itm.data,
                groupRange: {
                  ...itm.data.groupRange,
                  range: currentRange
                },
                isPeriodUpdate: true
              }
            }))
          }

          return obj;
        }, []);

        return r;
      })
    )),
    mergeMap((d) => d as Action[])
  ));

  private _onPeriodCost$ = createEffect(() => this._actions$.pipe(
    ofType(periodCost),
    switchMap((action) => this._api
      .periodCost$(action.data)
      .pipe(
        map((res) => periodCostSuccess(res, action)),
        catchError((err) => of(periodCostFail(err, action)))
      )
    )
  ))

  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _transactionDebitEntity: TransactionDebitEntity,
    private _userEntity: UserEntity,
    private _api: TransactionDebitBaseApi
  ) {}
}
