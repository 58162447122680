<div
  class="__wrap"
  [class.has-filters]="showFilters"
  [class.no-load-more]="!state.showLoadMore"
  [style.height]="scrollHeight">

  <div class="__filters" *ngIf="showFilters">

    <!-- container filter -->
    <div class="__filter-item">

      <button
        (click)="containerPopRef.open()"
        [satPopoverAnchor]="containerPopRef"
        mat-flat-button
        class="c-button--smaller  __filter-button">
        <mat-icon class="fx:align-middle  __filter-item_icon" fontSet="material-icons-outlined">dns</mat-icon>
        <span>{{ containerMap[state.params?.containerId]?.hostname }}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <sat-popover
        #containerPopRef
        [autoFocus]="false"
        [restoreFocus]="false"
        hasBackdrop
        (backdropClicked)="containerPopRef.close()"
        [verticalAlign]="'below'"
        [horizontalAlign]="'center'">
        <mat-card class="__filter-pop-card  z-manual-elevation  c-soft-elevation">
          <mat-action-list class="__filter-action-list" dense>
            <button
              class="__filter-option"
              (click)="filterChange.emit({ containerId: item.id }); containerPopRef.close();"
              *ngFor="let item of containers; trackBy: trackByIndex;"
              [class.is-active]="state.params?.containerId === item.id"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [label]="item.hostname"
              />
            </button>
          </mat-action-list>

        </mat-card>
      </sat-popover>

    </div>

    <zui-separator
      class="__filter-item_sep"
      [size]="'small'"
      [spacing]="'none'"
      [orientation]="'horizontal'"
    />

    <ng-template [ngIf]="[
      serviceStackTypes.PhpNginx,
      serviceStackTypes.PhpApache,
      serviceStackTypes.Elasticsearch
    ].includes(serviceStack?.serviceStackTypeId)">

      <!-- source type -->
      <div class="__filter-item">

        <button
          mat-flat-button
          (click)="sourcePopRef.open()"
          [satPopoverAnchor]="sourcePopRef"
          class="c-button--smaller  __filter-button">
          <mat-icon class="fx:align-middle  __filter-item_icon" fontSet="material-icons-outlined">call_split</mat-icon>

          <ng-container *ngIf="!state.params?.source">
            Access Logs + {{ serviceStack?.serviceStackTypeId === serviceStackTypes.Elasticsearch ? 'DB' : 'PHP' }} Logs
          </ng-container>

          <ng-container *ngIf="state.params?.source === 'access-logs'">
            Access Logs
          </ng-container>

          <ng-container *ngIf="state.params?.source === 'logs'">
            {{ serviceStack?.serviceStackTypeId === serviceStackTypes.Elasticsearch ? 'DB' : 'PHP' }} Logs
          </ng-container>

          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <sat-popover
          #sourcePopRef
          [autoFocus]="false"
          [restoreFocus]="false"
          hasBackdrop
          (backdropClicked)="sourcePopRef.close()"
          [verticalAlign]="'below'"
          [horizontalAlign]="'center'">
          <mat-card class="__filter-pop-card  z-manual-elevation  c-soft-elevation">

            <mat-action-list class="__filter-action-list" dense>
              <button
                class="__filter-option"
                [class.is-active]="!state.params?.source"
                (click)="filterChange.emit({ source: undefined }); sourcePopRef.close();"
                mat-list-item
                type="button">
                <zui-action-list-item
                  [mode]="'dense'"
                  [label]="'Access Logs + ' + (serviceStack?.serviceStackTypeId === serviceStackTypes.Elasticsearch ? 'DB' : 'PHP') + ' Logs'"
                />
              </button>
            </mat-action-list>

            <mat-action-list class="__filter-action-list" dense>
              <button
                class="__filter-option"
                [class.is-active]="!state.params?.source === 'access-logs'"
                (click)="filterChange.emit({ source: 'access-logs' }); sourcePopRef.close();"
                mat-list-item
                type="button">
                <zui-action-list-item
                  [mode]="'dense'"
                  [label]="'Access Logs'"
                />
              </button>
            </mat-action-list>

            <mat-action-list class="__filter-action-list" dense>
              <button
                class="__filter-option"
                [class.is-active]="!state.params?.source === 'logs'"
                (click)="filterChange.emit({ source: 'logs' }); sourcePopRef.close();"
                mat-list-item
                type="button">
                <zui-action-list-item
                  [mode]="'dense'"
                  [label]="(serviceStack?.serviceStackTypeId === serviceStackTypes.Elasticsearch ? 'DB' : 'PHP') + ' Logs'"
                />
              </button>
            </mat-action-list>

          </mat-card>
        </sat-popover>

      </div>

    </ng-template>

    <!-- severity filter -->
    <div class="__filter-item">

      <button
        mat-flat-button
        (click)="severityPopRef.open()"
        [satPopoverAnchor]="severityPopRef"
        class="c-button--smaller  __filter-button">
        <mat-icon class="fx:align-middle  __filter-item_icon" fontSet="material-icons-outlined">outlined_flag</mat-icon>
        <span><strong>{{ severitiesMap[state.params.minimumSeverity]?.name }}</strong></span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <sat-popover
        #severityPopRef
        [autoFocus]="false"
        [restoreFocus]="false"
        hasBackdrop
        (backdropClicked)="severityPopRef.close()"
        [verticalAlign]="'below'"
        [horizontalAlign]="'center'">
        <mat-card class="__filter-pop-card  z-manual-elevation  c-soft-elevation">
          <mat-action-list class="__filter-action-list" dense>
            <button
              class="__filter-option"
              *ngFor="let item of severities; trackBy: trackByIndex;"
              [class.is-active]="state.params?.minimumSeverity === item.value"
              (click)="filterChange.emit({ minimumSeverity: item.value }); severityPopRef.close();"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [label]="item.name + ' (' + item.value + ')'"
              />
            </button>
          </mat-action-list>
        </mat-card>
      </sat-popover>

    </div>

    <!-- range filter -->
    <div class="__filter-item">

      <button
        mat-flat-button
        (click)="onTimePopRefOpen(state.params?.mode); timePopRef.open()"
        [satPopoverAnchor]="timePopRef"
        class="c-button--smaller  __filter-button">
        <mat-icon class="fx:align-middle  __filter-item_icon" fontSet="material-icons-outlined">date_range</mat-icon>

        <!-- tail -->
        <span *ngIf="state.params?.mode === 'tail'">Tail Log <span *ngIf="state.itemsLength && !state.isLive">(paused)</span></span>

        <!-- range -->
        <span *ngIf="state.params?.mode === 'range'">
          Custom Range <span class="__filter-button_more-info">{{ state.params?.from | date: 'short' }} — {{ state.params?.till | date: 'short' }}</span>
        </span>

        <!-- limit -->
        <span *ngIf="state.params?.mode === 'limit'">
          Last
          <ng-container [ngSwitch]="state.params?.limit">
            <span *ngSwitchCase="'1000'">1000 {{ state.itemsLength > 1000 ? ('(+' + (state.itemsLength - 1000) + ')') : ''}}</span>
            <span *ngSwitchCase="'5000'">5000 {{ state.itemsLength > 5000 ? ('(+' + (state.itemsLength - 5000) + ')') : ''}}</span>
            <span *ngSwitchCase="'20000'">20000 {{ state.itemsLength > 20000 ? ('(+' + (state.itemsLength - 20000) + ')') : ''}}</span>
            <span *ngSwitchDefault>1000 {{ state.itemsLength > 1000 ? ('(+' + (state.itemsLength - 1000) + ')') : ''}}</span>
          </ng-container>
          Logs
        </span>

        <!-- relative period -->
        <span *ngIf="state.params?.mode === 'relPeriod'">
          {{ periodsMap.relative[state.params.period]?.name }}
        </span>

        <!-- absolute period -->
        <span *ngIf="state.params?.mode === 'absPeriod'">
          {{ periodsMap.absolute[state.params.period]?.name }}
        </span>

        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>

      <sat-popover
        #timeRangePopRef
        [autoFocus]="false"
        [restoreFocus]="false"
        [verticalAlign]="'start'"
        [horizontalAlign]="'after'">
        <mat-card class="__time-range-card" *ngIf="!!customRangeMode">

          <zef-form>

            <zef-form-field class="__time-range-form-field">
              <mat-form-field [color]="'accent'">

                <mat-label>From</mat-label>

                <mat-datetimepicker-toggle [for]="fromDatetimePickerRef" matSuffix />

                <mat-datetimepicker
                  #fromDatetimePickerRef
                  type="datetime"
                  timeInterval="1"
                />

                <input
                  [formControl]="rangeControl.get('from')"
                  matInput
                  [matDatetimepicker]="fromDatetimePickerRef"
                  required
                  placeholder="Select From date & time.."
                  autocomplete="false"
                />

              </mat-form-field>
            </zef-form-field>

            <zef-form-field class="__time-range-form-field">
              <mat-form-field [color]="'accent'">

                <mat-label>Till</mat-label>

                <mat-datetimepicker-toggle [for]="tillDatetimePickerRef" matSuffix />

                <mat-datetimepicker
                  #tillDatetimePickerRef
                  type="datetime"
                  timeInterval="1"
                />

                <input
                  [formControl]="rangeControl.get('till')"
                  matInput
                  [matDatetimepicker]="tillDatetimePickerRef"
                  required
                  placeholder="Select Till date & time.."
                  autocomplete="false"
                />

              </mat-form-field>
            </zef-form-field>

          </zef-form>

          <button
            (click)="applyCustomRange();"
            color="accent"
            class="c-button--full"
            mat-flat-button>
            Apply custom range
          </button>

        </mat-card>

      </sat-popover>

      <sat-popover
        #timePopRef
        [autoFocus]="false"
        [restoreFocus]="false"
        hasBackdrop
        (closed)="
          customRangeMode = false;
          timeRangePopAnchorRef.popover.close()
        "
        (backdropClicked)="timePopRef.close()"
        [verticalAlign]="'below'"
        [horizontalAlign]="'center'">
        <mat-card class="__filter-pop-card  z-manual-elevation  c-soft-elevation">
          <mat-action-list class="__filter-action-list" dense>

            <!-- tail -->
            <button
              class="__filter-option"
              [class.is-active]="state.params?.mode === 'tail'"
              (click)="filterChange.emit({
                mode: 'tail',
                from: null,
                till: null,
                limit: null,
                period: null
              }); timePopRef.close();"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [afterLabelIcon]="'play_arrow'"
                [label]="'Tail Log'"
              />
            </button>

            <!-- custom range -->
            <button
              #timeRangePopAnchorRef="satPopoverAnchor"
              class="__filter-option"
              [class.is-active]="state.params?.mode === 'range'"
              [class.is-opened]="customRangeMode === true"
              [satPopoverAnchor]="timeRangePopRef"
              (click)="onTimeRangePopOpen()"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [afterLabelIcon]="'date_range'"
                [label]="'Custom range'"
              />
            </button>

            <div mat-subheader>Relative</div>

            <!-- last x -->
            <button
              class="__filter-option"
              [class.is-active]="state.params?.mode === 'limit' && state.params?.limit === 1000"
              (click)="filterChange.emit({
                mode: 'limit',
                limit: '1000',
                period: null,
                from: null,
                till: null
              }); timePopRef.close();"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [label]="'Last 1000 logs'"
              />
            </button>

            <button
              class="__filter-option"
              [class.is-active]="state.params?.mode === 'limit' && state.params?.limit === 5000"
              (click)="filterChange.emit({
                mode: 'limit',
                limit: '5000',
                period: null,
                from: null,
                till: null
              }); timePopRef.close();"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [label]="'Last 5000 logs'"
              />
            </button>

            <button
              class="__filter-option"
              [class.is-active]="state.params?.mode === 'limit' && state.params?.limit === 20000"
              (click)="filterChange.emit({
                mode: 'limit',
                limit: '20000',
                period: null,
                from: null,
                till: null
              }); timePopRef.close();"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [label]="'Last 20000 logs'"
              />
            </button>

            <!-- relative period -->
            <button
              *ngFor="let item of periods.relative; trackByIndex"
              class="__filter-option"
              (click)="filterChange.emit({
                mode: 'relPeriod',
                period: item.key,
                from: null,
                till: null,
                limit: null
              }); timePopRef.close();"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [label]="item.name"
              />
            </button>

            <div mat-subheader>Absolute</div>

            <!-- absolute period -->
            <button
              *ngFor="let item of periods.absolute; trackByIndex"
              class="__filter-option"
              (click)="filterChange.emit({
                mode: 'absPeriod',
                period: item.key,
                from: null,
                till: null,
                limit: null
              }); timePopRef.close();"
              mat-list-item
              type="button">
              <zui-action-list-item
                [mode]="'dense'"
                [label]="item.name"
              />
            </button>

          </mat-action-list>
        </mat-card>
      </sat-popover>

    </div>

  </div>

  <div class="__log-viewport" #viewportRef>

    <ng-container *ngIf="!!viewportWidth">

      <zef-scrollbar
        [height]="scrollHeight"
        theme="os-theme-virtual-scrolling"
        [virtualScrollKey]="virtualScrollKey">
        <div
          class="__log-scroll-el"
          data-overlayscrollbars-initialize
          rxVirtualScrollElement>

          <div class="__load-more-wrap" *ngIf="state.showLoadMore || showFilters">
            <zef-button-progress
              class="__load-more-progress"
              [class.is-active]="state.delayedShow"
              [active]="state.isLoadMoreLoading"
              *ngIf="state.showLoadMore && !!state.itemsLength">
              <button
                class="c-button--smaller  __load-more-button"
                mat-stroked-button
                [disabled]="state.isLoadMoreLoading"
                (click)="loadMore()">
                <mat-icon>keyboard_arrow_up</mat-icon>&nbsp;<span>Load more</span>
              </button>
            </zef-button-progress>
          </div>

          <zui-three-dots *ngIf="state.isLoading" class="__loading"></zui-three-dots>

          <rx-virtual-scroll-viewport
            class="__scroller"
            [class.is-active]="state.delayedShow"
            [dynamic]="dynamicSize"
            (scrolledIndexChange)="scrollIndexChange$.next($event)">
            <div class="__item" *rxVirtualFor="let item of items$; trackBy: 'id'; renderCallback: itemsRendered$">
              <span
                [class.has-severity-emergency]="item.severity === 0"
                [class.has-severity-alert]="item.severity === 1"
                [class.has-severity-critical]="item.severity === 2"
                [class.has-severity-error]="item.severity === 3"
                [class.has-severity-warning]="item.severity === 4"
                [class.has-severity-notice]="item.severity === 5"
                [class.has-severity-informational]="item.severity === 6"
                [class.has-severity-debug]="item.severity === 7"
                class="__severity"></span><span class="__timestamp">{{ item.timestamp }}</span><span class="__content">{{ item.content }}</span>
            </div>
          </rx-virtual-scroll-viewport>

        </div>
      </zef-scrollbar>

    </ng-container>

  </div>

  <div class="__options-controls" *ngIf="!!state.itemsLength">

    <button
      class="c-button--smaller __tail-button"
      (click)="setLive()"
      type="button"
      *ngIf="!state.isLive && !!state.options?.live"
      mat-raised-button>
      <span>Start Tailing Log</span>&nbsp;<mat-icon fontSet="material-icons-outlined">play_arrow</mat-icon>
    </button>

    <button
      class="c-button--smaller  __tail-button"
      (click)="setFollow(true)"
      type="button"
      *ngIf="state.isLive && !!state.options?.live && !state.isFollowing"
      mat-raised-button>
      <mat-icon fontSet="material-icons-outlined">arrow_downward</mat-icon>&nbsp;<span>Follow Live Log</span>
    </button>

    <div class="__scroll-buttons">

      <button
        class="__scroll-button"
        (click)="setFollow()"
        *ngIf="!state.isLive"
        mat-icon-button>
        <mat-icon>arrow_downward</mat-icon>
      </button>

      <button
        class="__scroll-button"
        (click)="toTop()"
        *ngIf="!state.isLive || (state.isLive && !state.isLiveCancellable)"
        mat-icon-button>
        <mat-icon>arrow_upward</mat-icon>
      </button>

    </div>

  </div>

</div>