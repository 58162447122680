<zui-process-notification-shell
  class="__shell"
  [class.is-unread]="!data?.acknowledged && !hideUnread"
  [isSystem]="actionsMap[data?.actionName]?.isSystem || data?.createdByUser?.type === 'SYSTEM'"
  [fullName]="data?.createdByUser?.fullName"
  [email]="data?.createdByUser?.email"
  [image]="data?.createdByUser?.avatar?.smallAvatarUrl"
  [externalImage]="data?.createdByUser?.avatar?.externalAvatarUrl"
  [createdByType]="data?.createdByUser.type"
  [created]="data?.created"
  [firstName]="data?.createdByUser?.firstName"
  [prefixText]="'Initialized by'"
  [color]="color ? color : ((typeColorMap[data?.type] || 'base'))">

  <zui-progress-spinner
    *ngIf="!data?.acknowledged && showMarkAsRead"
    [matTooltip]="translations.markAsReadTooltip"
    class="__read-spinner"
    [diameter]="14"
    [isLoading]="markAsReadLoading"
    hideContent>
    <button
      class="__read-button"
      type="button"
      [disabled]="markAsReadLoading"
      (click)="markAsRead.emit(data.id)"
      mat-icon-button>
      <mat-icon class="__read-icon">fiber_manual_record</mat-icon>
    </button>
  </zui-progress-spinner>

  <ng-container *zefMessageFormat="let mf">

    <!-- build & deploy pipeline steps -->
    <ng-container *ngIf="data?.appVersion && (
      data?.type === 'ERROR'
      || data?.type === 'SUCCESS'
      || (data?.type === 'WARNING' && data?.appVersion.status === appVersionStatuses.Cancelled)
    )">

      <zui-pipeline-desc
        [serviceName]="data?.serviceStacks[0].name"
        [serviceType]="data?.serviceStacks[0].serviceStackTypeInfo?.serviceStackTypeName"
        [serviceTypeId]="data?.serviceStacks[0]?.serviceStackTypeId"
        [projectName]="data?.project.name"
        [serviceId]="data?.serviceStacks[0].id"
        [projectId]="data?.project?.id"
        [appVersion]="data.appVersion"
        [actionName]="data.actionName"
        [status]="data?.type"
        [disableLink]="disableLink"
        [errorData]="data.error"
        (linkClicked)="internalLinkClicked.emit()">
      </zui-pipeline-desc>

      <div
        *ngIf="data?.error?.code !== zeropsYamlErrors.ZeropsBuildFileNotFound
          && data?.error?.code !== zeropsYamlErrors.YamlValidationInvalidYaml
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlSetupNotFound
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlServiceNotFound
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlInvalidYaml
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlTooManyExtends
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlInvalidParameter
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlMissingParameter
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlDocumentRootRequired
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlStartCommandRequired
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlNoProbeSpecified
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlTooManyProbes
          && data?.error?.code !== zeropsYamlErrors.ZeropsBuildFileTooLarge
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlRunOsRequired
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlRunBaseRequired
          && data?.error?.code !== zeropsYamlErrors.ZeropsYamlRunOsBaseInvalid
          && data?.error?.code !== zeropsYamlErrors.InternalServerError
          && data?.appVersion.status !== appVersionStatuses.Cancelled"
        class="__process-time">
        <zui-process-notification-duration
          [type]="data?.type === 'SUCCESS' ? 'FINISHED' : 'FAILED'"
          [start]="data?.appVersion?.build?.pipelineStart ? data?.appVersion?.build?.pipelineStart : data?.actionStarted"
          [end]="data?.appVersion?.build?.pipelineFailed
            ? data?.appVersion?.build?.pipelineFailed
            : data?.appVersion?.build?.pipelineFinish
              ? data?.appVersion?.build?.pipelineFinish
              : data?.actionFinished">
        </zui-process-notification-duration>
      </div>

      <!-- zerops.yml error -->
      <div
        *ngIf="data?.error?.code === zeropsYamlErrors.ZeropsBuildFileNotFound
          || data?.error?.code === zeropsYamlErrors.YamlValidationInvalidYaml
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlSetupNotFound
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlServiceNotFound
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlInvalidYaml
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlTooManyExtends
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlInvalidParameter
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlMissingParameter
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlDocumentRootRequired
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlStartCommandRequired
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlNoProbeSpecified
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlTooManyProbes
          || data?.error?.code === zeropsYamlErrors.ZeropsBuildFileTooLarge
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlRunOsRequired
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlRunBaseRequired
          || data?.error?.code === zeropsYamlErrors.ZeropsYamlRunOsBaseInvalid
          || data?.error?.code === zeropsYamlErrors.InternalServerError"
        class="__error-card">

          <!-- Start: New solution for displaying BE errors. -->
          <zef-scrollbar
            *ngIf="!!data?.error?.meta && !!data.error.meta?.[0]?.error"
            maxHeight="200px">
            <zui-errors-printer
              [pipelineErrors]="data.error.meta">
            </zui-errors-printer>
          </zef-scrollbar>
          <!-- End: New solution for displaying BE errors. -->

          <!-- Start: Old solution for displaying BE errors. -->
          <div
            *ngIf="!data?.error?.meta || !data.error.meta?.[0]?.error"
            class="__error-info">

            <zui-docs-text>
              <span [innerHTML]="translations.zeropsYamlErrors[data?.error?.code] | safeHtml"></span>
            </zui-docs-text>

            <pre class="__error-info_meta" *ngIf="data.error?.meta">{{ data.error?.meta | json }}</pre>

          </div>
          <!-- End: Old solution for displaying BE errors. -->
      </div>

      <!-- pipeline steps for errors -->
      <ng-container *ngIf="data?.type === 'ERROR'
        && data?.actionName !== 'stack.deploy.backup'
        && data?.appVersion.status !== appVersionStatuses.Backup
        && data?.error?.code !== zeropsYamlErrors.ZeropsBuildFileNotFound
        && data?.error?.code !== zeropsYamlErrors.YamlValidationInvalidYaml
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlSetupNotFound
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlServiceNotFound
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlInvalidYaml
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlTooManyExtends
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlInvalidParameter
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlMissingParameter
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlDocumentRootRequired
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlStartCommandRequired
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlNoProbeSpecified
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlTooManyProbes
        && data?.error?.code !== zeropsYamlErrors.ZeropsBuildFileTooLarge
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlRunOsRequired
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlRunBaseRequired
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlRunOsBaseInvalid
        && data?.error?.code !== zeropsYamlErrors.InternalServerError">

        <zui-separator
          class="__separator"
          [position]="'start'"
          [size]="'mini'"
          [spacing]="'mini'">
        </zui-separator>

        <zui-build-state-steps
          class="__build-steps"
          [serviceName]="data?.serviceStacks[0]?.name"
          [serviceType]="data?.serviceStacks[0]?.serviceStackTypeInfo?.serviceStackTypeName"
          [projectId]="data?.project?.id"
          [appVersion]="data?.appVersion"
          [pipelineErrors]="data?.error?.meta">
        </zui-build-state-steps>

      </ng-container>

      <!-- This code part is already superfluous. Its covered by <zui-build-state-steps> component. -->
      <div
        *ngIf="false && data?.appVersion.status === 'DEPLOY_FAILED' && (!!data.error?.message || !!data.error?.meta)"
        class="__error-card">

          <!-- Start: New solution for displaying BE errors. -->
          <zef-scrollbar
            *ngIf="!!data?.error?.meta && !!data.error.meta?.[0]?.error"
            maxHeight="200px">
            <zui-errors-printer
              [pipelineErrors]="data.error.meta">
            </zui-errors-printer>
          </zef-scrollbar>
          <!-- End: New solution for displaying BE errors. -->

          <!-- Start: Old solution for displaying BE errors. -->
          <div
            *ngIf="!data?.error?.meta || !data.error.meta?.[0]?.error"
            class="__error-info">

            <ng-container *ngIf="!!data.error?.message">
              {{ data.error?.message }}
            </ng-container>

            <pre class="__error-info_meta" *ngIf="!!data.error?.meta">{{ data.error?.meta | json }}</pre>

          </div>
          <!-- End: Old solution for displaying BE errors. -->

      </div>

      <!-- pipeline detail button -->
      <ng-container *ngIf="
        data?.actionName !== 'stack.deploy.backup'
        && data?.error?.code !== zeropsYamlErrors.YamlValidationInvalidYaml
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlSetupNotFound
        && data?.error?.code !== zeropsYamlErrors.ZeropsBuildFileNotFound
        && data?.error?.code !== zeropsYamlErrors.ZeropsYamlInvalidYaml
        && data?.error?.code !== zeropsYamlErrors.InternalServerError
        && data?.appVersion?.source !== 'GUI'
        && (!!data?.appVersion?.build || !!data?.appVersion?.prepareCustomRuntime)
        && data?.appVersion.status !== appVersionStatuses.Cancelled
      ">

        <zui-separator
          class="__separator"
          [position]="'start'"
          [size]="'mini'"
          [spacing]="'mini'">
        </zui-separator>

        <button
          (click)="pipelineDetailClicked.emit({ appVersion: data?.appVersion, pipelineErrors: data?.error?.meta })"
          mat-flat-button
          [class.is-success]="data?.type === 'SUCCESS'"
          [class.is-fail]="data?.type !== 'SUCCESS'"
          class="[ c-button  c-button--smaller ]  __pipeline-button"
          type="button">
          Open pipeline detail <mat-icon>aspect_ratio</mat-icon>
        </button>

      </ng-container>

    </ng-container>

    <!-- other -->
    <ng-container *ngIf="!data.appVersion">

      <zui-process-notification-desc
        [disableLink]="disableLink"
        (linkClicked)="internalLinkClicked.emit()">

        <span
          *ngIf="(data.actionName + '_' + data.type) === 'stack.create_SUCCESS'
            && data?.serviceStacks[0].serviceStackTypeInfo?.serviceStackTypeCategory !== serviceStackTypeCategories.User;
              else userStackCreateSuccessRef"
          [innerHTML]="mf(translations['runtime_' + (data.actionName | normalizeActionName) + '_' + data.type], {
            serviceType: data?.serviceStacks[0]?.serviceStackTypeInfo?.serviceStackTypeName,
            serviceName: data?.serviceStacks[0]?.name,
            serviceId: data?.serviceStacks[0]?.id,
            projectId: data?.project?.id,
            projectName: data?.project?.name
          }) | safeHtml">
        </span>

        <ng-template #userStackCreateSuccessRef>
          <span
            [innerHTML]="(translations && translations[(data.actionName | normalizeActionName) + '_' + data.type]
              ? mf(translations[(data.actionName | normalizeActionName) + '_' + data.type], {
                  serviceType: data?.serviceStacks[0]?.serviceStackTypeInfo?.serviceStackTypeName,
                  serviceName: data?.serviceStacks[0]?.name,
                  serviceId: data?.serviceStacks[0]?.id,
                  projectId: data?.project?.id,
                  projectName: data?.project?.name,
                  metadataLtsLessThanDays: data?.metadata?.ltsLessThanDays || 'few'
                })
              : data.actionName + '_' + data.type
            ) | safeHtml">
          </span>
        </ng-template>

      </zui-process-notification-desc>

      <div
        *ngIf="!!data.error?.message || !!data.error?.meta"
        class="__error-card">

          <!-- Start: New solution for displaying BE errors. -->
          <zef-scrollbar
            *ngIf="!!data?.error?.meta && !!data.error.meta?.[0]?.error"
            maxHeight="200px">
            <zui-errors-printer
              [pipelineErrors]="data.error.meta">
            </zui-errors-printer>
          </zef-scrollbar>
          <!-- End: New solution for displaying BE errors. -->

          <!-- Start: Old solution for displaying BE errors. -->
          <div
            *ngIf="!data?.error?.meta || !data.error.meta?.[0]?.error"
            class="__error-info">

            <ng-container *ngIf="!!data.error?.message">
              {{ data.error?.message }}
            </ng-container>

            <pre class="__error-info_meta" *ngIf="!!data.error?.meta">{{ data.error?.meta | json }}</pre>

          </div>
          <!-- End: Old solution for displaying BE errors. -->
      </div>

      <div
        *ngIf="data?.type !== 'WARNING' && !actionsMap[data?.actionName]?.disableDuration"
        class="__process-time">
        <zui-process-notification-duration
          [type]="data?.type === 'SUCCESS' ? 'FINISHED' : 'FAILED'"
          [start]="data?.actionStarted"
          [end]="data?.actionFinished">
        </zui-process-notification-duration>
      </div>

      <!-- runtime error -->
      <!-- TODO: TEMP DISABLED UNTIL NOTIFICATION ARE GENERATED IN HEALTHCHECK -->
      <!-- <button
        *ngIf="data?.type === 'ERROR' && !actionsMap[data?.actionName]?.disabledLog"
        class="__log-button is-failed  c-button--smaller"
        mat-button
        (click)="logClicked.emit({
          serviceStackId: data?.serviceStacks?.[0]?.id,
          projectId: data?.project?.id,
          containerId: data?.containerId,
          from: data?.actionCreated,
          to: data?.actionFinished
        })">
        Show log {{ data?.actionFinished | date: 'medium' }}
      </button> -->

    </ng-container>

  </ng-container>

</zui-process-notification-shell>
