import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorComponent } from  'ngx-monaco-editor-v2';

export class EditorOptions {
  theme: string;
  language: string;
  padding: {
    top: number;
    bottom: number;
  };
  roundedSelection: boolean;
  autoIndent: string;
  scrollBeyondLastLine: boolean;
  automaticLayout: boolean;
}

@Component({
  selector: 'zui-code-field',
  templateUrl: './code-field.component.html',
  styleUrls: [ './code-field.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeFieldComponent),
      multi: true
    }
  ]
})
export class CodeFieldComponent implements ControlValueAccessor, AfterViewInit {

  defaultEditorOptions = {
    theme: 'vs-dark',
    padding: {
      top: 24,
      bottom: 24
    },
    roundedSelection: true,
    autoIndent: 'full',
    scrollBeyondLastLine: false,
    automaticLayout: true,
    minimap: {
      enabled: false
    },
    tabSize: 2,
    scrollbar: {
      alwaysConsumeMouseWheel: false,
    },
    renderWhitespace: 'all'
  };
  private _editorOptions: Partial<EditorOptions> = this.defaultEditorOptions;

  @Input()
  code: string;

  @Input()
  loading = false;

  @Input()
  set editorOptions(v) {
    if (v) {
      this._editorOptions = this._mergeEditorOptions(v);
    }
  }
  get editorOptions() {
    return this._editorOptions;
  }

  @Output()
  editorInit = new EventEmitter<any>();

  @ViewChild(EditorComponent, { static: true })
  editorComponent: EditorComponent;

  @Input()
  set value(value: string) {
    this._value = value;
    this.writeValue(value);
  }
  get value(): string {
    return this._value;
  }

  private _value: string;

  onChange = (_: any) => {
    return;
  };
  onTouched = (_: any) => {
    return;
  };

  writeValue(obj: any): void {
    this._value = obj;
    this.editorComponent.writeValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.editorComponent.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.editorComponent.registerOnTouched(fn);
  }

  ngAfterViewInit(): void {
    this.editorComponent.registerOnChange(this.onChange);
    this.editorComponent.registerOnTouched(this.onTouched);
  }

  private _mergeEditorOptions(editorOptions: Partial<EditorOptions>) {
    return {
      ...this.defaultEditorOptions,
      ...editorOptions
    };
  }
}
