<div class="__wrap">

  <ngx-monaco-editor
    class="__editor c-code-field-editor"
    [options]="editorOptions"
    (onInit)="editorInit.emit($event)"
    [(ngModel)]="value || code">
  </ngx-monaco-editor>

  <mat-spinner
    *ngIf="loading"
    class="__spinner"
    [diameter]="25">
  </mat-spinner>

</div>