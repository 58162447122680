<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  (closeTrigger)="onClose$.next()">
  <div *ngIf="state.data as data" class="__content">

    <h3 class="__title">{{ state.translations.entityDeleted }}</h3>

    <p
      class="__desc"
      [innerHTML]="state.translations.desc | safeHtml">
    </p>

    <button
      mat-stroked-button
      type="button"
      [routerLink]="!!data.routerLink && data.routerLink.length ? data.routerLink : [ '/' ]"
      (click)="onClose$.next()"
      class="__button [ c-button--full  c-button--larger c-button--routing ]">

      {{ !!data.buttonText ? data.buttonText : state.translations.goToDashboard }}

      <mat-icon>navigate_next</mat-icon>

    </button>

  </div>
</zef-dialog>
