import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import {
  selectZefGithubRepositories,
  selectZefGithubBranchMap,
  zefGithubRepositoriesRequest,
  zefGithubBranchRequest,
  selectZefGithubAuthState,
  ZefGithubRepository,
  ZEF_GITHUB_AUTH_PROGRESS_KEY
} from '@zerops/zef/github';
import { selectZefProgressByType } from '@zerops/zef/progress';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { ZefFuseService } from '@zerops/zef/fuse';
import { SatPopover } from '@zerops/zef/popover';
import { ObservableInput } from 'observable-input';
import { SetValueAction, box } from 'ngrx-forms';
import { Subject, combineLatest, Observable } from 'rxjs';
import {
  map,
  startWith,
  withLatestFrom,
  distinctUntilChanged,
  filter,
  pairwise,
  takeUntil,
  delay,
  switchMap,
  take
} from 'rxjs/operators';
import { KnowledgeBaseEntities } from '@zerops/models/knowledge-base';
import { AppState } from '@zerops/zerops/app';
import { FEATURE_NAME } from './github-repo-form.constant';

type States = 'INITIAL' | 'AUTHORIZED' | 'SELECTED';

const fuseSearchKeys = [{ name: 'fullName', weight: 0.99 }];

@Component({
  selector: 'z-github-repo-form',
  templateUrl: './github-repo-form.container.html',
  styleUrls: [ './github-repo-form.container.scss' ]
})
export class GithubRepoFormContainer extends ZefReactiveComponent {

  // # Event Streams
  onRepoConnectionRequest$ = new Subject<boolean>();
  onSelectRepository$ = new Subject<string>();
  onSelectBuildBase$ = new Subject<string>();
  onResetForm$ = new Subject<void>();
  onSetTriggerBuildValue$ = new Subject<void>();

  // # Local Forms
  repoSearchForm = new UntypedFormControl();

  // # Data
  // -- angular
  @ObservableInput()
  @Input('formState')
  formState$!: Observable<any>;

  @Input()
  showDisconnectButton = true;

  @Input()
  triggerBuildLabel: string;

  @ViewChild('repoSearchPopRef')
  repoSearchPopRef: SatPopover;

  @ViewChild('repoSearchInputRef')
  repoSearchInputRef: ElementRef;

  // -- sync
  knowledgeBaseEntities = KnowledgeBaseEntities;

  // -- async
  repositories$ = this._store.pipe(select(selectZefGithubRepositories));
  searchedRepositories$ = combineLatest([
    this.repositories$.pipe(filter((d) => !!d?.length)),
    this.repoSearchForm.valueChanges.pipe(startWith(undefined as string))
  ]).pipe(
    map(([ repositories, searchVal ]) => this._fuse.search(
      repositories,
      searchVal,
      fuseSearchKeys,
      FEATURE_NAME
    ))
  );
  repositoriesBranchMap$ = this._store.pipe(
    select(selectZefGithubBranchMap)
  );
  repositoriesLoading$ = this._store.pipe(select(
    selectZefProgressByType(zefGithubRepositoriesRequest.type)
  ));
  authorized$ = this._store.pipe(select(selectZefGithubAuthState));
  permissionLoading$ = this._store.pipe(select(
    selectZefProgressByType(ZEF_GITHUB_AUTH_PROGRESS_KEY)
  ));
  state$: Observable<States> = combineLatest([
    this.onRepoConnectionRequest$.pipe(startWith(null as string)),
    this.formState$,
    this.authorized$
  ]).pipe(
    map(([ wasRequested, formState, authorized ]) => {

      if (!wasRequested || (wasRequested && !authorized)) {
        return 'INITIAL';
      }

      if (wasRequested && authorized) {
        if (!!formState?.value?.repositoryFullName) {
          return 'SELECTED';
        }
        return 'AUTHORIZED';
      }

      return 'INITIAL';
    }),
    distinctUntilChanged<States>()
  );
  formValue$ = this.formState$.pipe(
    filter((s) => !!s),
    map((s) => s.value)
  );
  repoSearchOpenTrigger$ = this.state$.pipe(
    pairwise(),
    map(([ prev, curr ]) => !!(curr === 'AUTHORIZED' && prev === 'INITIAL'))
  );
  isSelectedBranchDefault$ = this.formValue$.pipe(
    switchMap(({ repositoryFullName, branchName }) => this.repositoriesBranchMap$.pipe(
      filter((d) => !!d),
      map((m) => m[repositoryFullName]),
      distinctUntilChanged(),
      filter((d) => !!d),
      take(1),
      map((d) => d.reduce(((obj, itm) => {
        obj[itm.name] = itm.isDefault;
        return obj;
      }), {})),
      map((m) => m[branchName])
    ))
  );

  // # State resolver
  state = this.$connect({
    repositories: this.repositories$,
    repositoriesBranchMap: this.repositoriesBranchMap$,
    searchedRepositories: this.searchedRepositories$,
    repositoriesLoading: this.repositoriesLoading$,
    permissionLoading: this.permissionLoading$,
    formState: this.formState$,
    state: this.state$,
    isSelectedBranchDefault: this.isSelectedBranchDefault$
  });

  // # Actions
  private _repoAuthAction$ = this.onRepoConnectionRequest$.pipe(
    map(() => zefGithubRepositoriesRequest(undefined, { type: 'noop' }))
  );
  private _resetFormAction$ = this.onResetForm$.pipe(
    withLatestFrom(this.formState$),
    map(([ _, formState ]) => new SetValueAction(formState.id, {
      repositoryFullName: undefined,
      buildServiceStackTypeVersionId: undefined,
      temporaryShutdown: false,
      isActive: true,
      buildTargets: box([]),
      eventType: 'TAG',
      branchName: undefined,
      triggerBuild: false
    }))
  );
  private _selectRepositoryAction$ = this.onSelectRepository$.pipe(
    withLatestFrom(this.formState$),
    map(([ name, formState ]) => new SetValueAction(
      formState?.controls?.repositoryFullName?.id,
      name
    ))
  );
  private _setTriggerBuildAction$ = this.onSetTriggerBuildValue$.pipe(
    withLatestFrom(this.formState$),
    map(([ _, formState ]) => new SetValueAction(
      formState?.controls?.triggerBuild?.id,
      false
    ))
  );
  private _selectRepositoryLoadBranchAction$ = this.onSelectRepository$.pipe(
    map((name) => zefGithubBranchRequest(name, { type: 'noop' }))
  );
  private _setDefaultBranchAction$ = this.formState$.pipe(
    filter((s) => !!s),
    distinctUntilChanged((a, b) => a.value.eventType === b.value.eventType),
    withLatestFrom(this.repositoriesBranchMap$),
    filter(([ _, branchMap ]) => !!branchMap),
    map(([ { controls, value }, branchMap ]) => {
      if (value.eventType === 'BRANCH') {
        return new SetValueAction(
          controls.branchName?.id,
          branchMap[value.repositoryFullName][0]?.name
        );
      }
      return new SetValueAction(
        controls.branchName?.id,
        undefined
      );
    })
  );

  constructor(
    private _store: Store<AppState>,
    private _fuse: ZefFuseService<ZefGithubRepository>,
  ) {
    super();

    // open repo search if we are going
    // from INITIAL to AUTHORIZED
    this.repoSearchOpenTrigger$
      .pipe(
        // let template render to make sure
        // repoSearchPopRef exists
        delay(0),
        takeUntil(this.onDestroy$)
      )
      .subscribe((shouldOpen) => {
        if (shouldOpen && !!this.repoSearchPopRef) {
          this.repoSearchPopRef.open();
        }
      });

    // # Dispatcher
    this.$dispatchActions(
      [
        this._repoAuthAction$,
        this._selectRepositoryAction$,
        this._resetFormAction$,
        this._setDefaultBranchAction$,
        this._selectRepositoryLoadBranchAction$,
        this._setTriggerBuildAction$
      ]
    );

  }

  focusRepoSearchInput() {
    setTimeout(() => {
      this.repoSearchInputRef?.nativeElement?.focus();
    }, 10);
  }

}
