<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxHeight: '80vh', maxWidth: '900px', width: '80vw' }"
  (closeTrigger)="onClose$.next()">
  <div
    class="__content"
    [class.is-dark-theme]="state.isDarkTheme">

    <h3 class="__title  fx:pb-1">

      <ng-container *ngIf="state.mode === modes.Import">
        Import
        {{ state.entity === dialogEntities.Project ? 'a project' : 'services' }} from YAML
      </ng-container>

      <ng-container *ngIf="state.mode === modes.Export">
        {{ state.entity === dialogEntities.Project ? 'Project' : 'Service' }} YAML configuration
      </ng-container>

    </h3>

    <zui-code-field
      class="__code-field"
      [ngrxFormControlState]="state.formState?.controls.yaml"
      [ngrxEnableFocusTracking]="true"
      [editorOptions]="{ language: 'yaml' }">
    </zui-code-field>

    <div class="__buttons">

      <button
        *ngIf="!!state.yaml && (!state.mode || state.mode === modes.Export)"
        class="__copy-button  c-button--full"
        mat-button
        color="accent"
        type="button"
        (click)="copy(state.formState?.controls.yaml?.value)">
        Copy to clipboard
      </button>

      <zef-progress-error
        *ngIf="state.mode === modes.Import"
        full
        [key]="state.entity === dialogEntities.Project ? projectImportKey : serviceStackImportKey"
        class="__newsletter-button">
        <button
          mat-flat-button
          class="c-button--full"
          color="accent"
          type="type"
          (click)="state.entity === dialogEntities.Project ? onProjectImport$.next() : onServiceStackImport$.next()">
          Import {{ state.entity ===  dialogEntities.Project ? 'project' : 'service' }}
        </button>
      </zef-progress-error>

    </div>

    <button
      (click)="onClose$.next()"
      class="__close-button c-button--full"
      type="button"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>

  </div>
</zef-dialog>
