import { AppVersion, AppVersionStatuses } from '@zerops/models/app-version';
import { BuildProcessStates } from './build-state-steps.model';

export function getPipelineState(appVersion?: Partial<AppVersion>) {
  if (!!appVersion) {
    if (appVersion?.status === AppVersionStatuses.WaitingToBuild && !appVersion?.build?.pipelineStart) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Waiting,
        RUN_BUILD_COMMANDS: BuildProcessStates.Waiting,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Waiting,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Waiting,
        DEPLOY: BuildProcessStates.Waiting
      };
    } else if ((appVersion?.status === AppVersionStatuses.Building
        || (!!appVersion?.build?.pipelineStart && appVersion?.status === AppVersionStatuses.WaitingToBuild))
          && !appVersion?.build?.startDate) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Running,
        RUN_BUILD_COMMANDS: BuildProcessStates.Waiting,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Waiting,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Waiting,
        DEPLOY: BuildProcessStates.Waiting
      };
    } else if (appVersion?.status === AppVersionStatuses.Building && !!appVersion?.build?.startDate && !appVersion?.build?.endDate) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Running,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Waiting,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Waiting,
        DEPLOY: BuildProcessStates.Waiting
      };
    } else if ((appVersion?.status === AppVersionStatuses.PreparingRuntime
        || (appVersion?.status === AppVersionStatuses.Building && !!appVersion?.build?.endDate)
        || (appVersion?.status === AppVersionStatuses.Deploying && !!appVersion?.build?.endDate && appVersion?.prepareCustomRuntime))
          && !appVersion?.prepareCustomRuntime?.startDate) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Finished,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Running,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Waiting,
        DEPLOY: BuildProcessStates.Waiting
      };
    } else if (appVersion?.status === AppVersionStatuses.PreparingRuntime && !!appVersion?.prepareCustomRuntime?.startDate) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Finished,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Finished,
        RUN_PREPARE_COMMANDS: appVersion?.prepareCustomRuntime?.endDate ? BuildProcessStates.Finished : BuildProcessStates.Running,
        DEPLOY: BuildProcessStates.Waiting
      };
    } else if (appVersion?.status === AppVersionStatuses.WaitingToDeploy) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Finished,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Finished,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Finished,
        DEPLOY: BuildProcessStates.Waiting
      };
    } else if (appVersion?.status === AppVersionStatuses.Deploying
        && (!appVersion?.prepareCustomRuntime || (appVersion?.prepareCustomRuntime && appVersion?.prepareCustomRuntime.endDate))
      ) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Finished,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Finished,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Finished,
        DEPLOY: appVersion?.activationDate ? BuildProcessStates.Activating : BuildProcessStates.Running
      };
    } else if (appVersion?.status === AppVersionStatuses.BuildFailed && !appVersion?.build?.startDate) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Failed,
        RUN_BUILD_COMMANDS: BuildProcessStates.Cancelled,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Cancelled,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Cancelled,
        DEPLOY: BuildProcessStates.Cancelled
      };
    } else if (appVersion?.status === AppVersionStatuses.BuildFailed && !!appVersion?.build?.startDate) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Failed,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Cancelled,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Cancelled,
        DEPLOY: BuildProcessStates.Cancelled
      };
    } else if (appVersion?.status === AppVersionStatuses.PreparingRuntimeFailed && !appVersion?.prepareCustomRuntime?.startDate) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Finished,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Failed,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Cancelled,
        DEPLOY: BuildProcessStates.Cancelled
      };
    } else if (appVersion?.status === AppVersionStatuses.PreparingRuntimeFailed && !!appVersion?.prepareCustomRuntime?.startDate) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Finished,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Finished,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Failed,
        DEPLOY: BuildProcessStates.Cancelled
      };
    } else if (appVersion?.status === AppVersionStatuses.DeployFailed) {
      return {
        INIT_BUILD_CONTAINER: BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: BuildProcessStates.Finished,
        INIT_PREPARE_CONTAINER: BuildProcessStates.Finished,
        RUN_PREPARE_COMMANDS: BuildProcessStates.Finished,
        DEPLOY: BuildProcessStates.Failed
      };
    } else if (appVersion?.status === AppVersionStatuses.Active || appVersion?.status === AppVersionStatuses.Backup) {
      return {
        INIT_BUILD_CONTAINER: !appVersion.build ? BuildProcessStates.Noop : BuildProcessStates.Finished,
        RUN_BUILD_COMMANDS: !appVersion.build ? BuildProcessStates.Noop : BuildProcessStates.Finished,
        INIT_PREPARE_CONTAINER: !appVersion.prepareCustomRuntime ? BuildProcessStates.Noop : BuildProcessStates.Finished,
        RUN_PREPARE_COMMANDS: !appVersion.prepareCustomRuntime ? BuildProcessStates.Noop : BuildProcessStates.Finished,
        DEPLOY: BuildProcessStates.Finished
      };
    }
  }

  return {
    INIT_BUILD_CONTAINER: BuildProcessStates.Noop,
    RUN_BUILD_COMMANDS: BuildProcessStates.Noop,
    INIT_PREPARE_CONTAINER: BuildProcessStates.Noop,
    RUN_PREPARE_COMMANDS: BuildProcessStates.Noop,
    DEPLOY: BuildProcessStates.Noop
  };
}
