<z-client-user-select-pop
  #clientUserSelectPopRef
  [clientUsers]="state.activeUserClientUsers"
  [title]="'Select the organization you want to switch to'">
</z-client-user-select-pop>

<zef-fade-animation
  [animationState]="active"
  [animationParams]="{
    enterDuration: '650ms',
    leaveDuration: '200ms',
    easing: 'cubic-bezier(0.4, 0.0, 0.2, 1)'
  }">

  <div class="__main-grid" [class.is-compact]="state.isCompact">

    <div class="__main-grid_logo">

      <button
        mat-button
        type="button"
        [satPopoverAnchor]="menuPopRef"
        (click)="menuPopRef.open()"
        class="__logo-button c-app-bar-logo-button">

        <div class="__controls-grid">
          <div>
            <z-global-loading [logoWidth]="21" class="__global-loading"></z-global-loading>
          </div>

          <div>
            @if (!!state.clientUser?.user) {
              <zef-avatar
                class="__user-avatar"
                [size]="30"
                [name]="state.clientUser.user.fullName"
                [email]="state.clientUser.user.email"
                [image]="state.clientUser.user.avatar?.smallAvatarUrl"
                [externalImage]="state.clientUser.user?.avatar?.externalAvatarUrl"
                [backgroundColor]="'#4c575c'">
              </zef-avatar>
            }
          </div>

          <div>
            <mat-icon class="__menu-trigger-icon">expand_more</mat-icon>
          </div>

        </div>

      </button>
    </div>

    <div class="__main-grid_notifications">
      <zui-notification-pop-trigger
        [matTooltip]="'Open notifications'"
        [unreadCount]="state.unreadNotificationsCount"
        [triggerRef]="notificationPopRef"
        (opened)="onOpenNotifications$.next()">
      </zui-notification-pop-trigger>
    </div>

    <div class="__main-grid_processes">
      <zui-processes-pop-trigger
        (opened)="onOpenProcesses$.next()"
        [triggerRef]="processesPopRef"
        [runningCount]="state.processesCount?.running || 0"
        [pendingCount]="state.processesCount?.queued || 0">
      </zui-processes-pop-trigger>
    </div>

  </div>


  <!-- <mat-icon
    class="__icon"
    [class.is-chevron]="menuState">
    {{ menuState ? 'arrow_back' : 'menu' }}
  </mat-icon> -->

  <!-- <div
    class="__grid"
    [class.has-white-theme]="logoTheme === 'white'"> -->


<!--
    <div class="u-text--center __grid-item">

      <zui-processes-pop-trigger
        (opened)="onOpenProcesses$.next()"
        [triggerRef]="processesPopRef"
        [runningCount]="state.processesCount?.running || 0"
        [pendingCount]="state.processesCount?.queued || 0">
      </zui-processes-pop-trigger>

    </div> -->

    <!-- <div class="u-text--right __grid-item"> -->

      <!-- TODO: uncomment when API ready -->
      <!-- <button
        mat-button
        (mouseenter)="searchToggleContentRef.toggle()"
        (mouseleave)="searchToggleContentRef.toggle()"
        class="__search-button  __app-bar-action-button">
        <zui-toggle-content
          class="__toggle-content __search-button_toggle-content"
          [activeIndex]="1"
          [position]="'end'"
          #searchToggleContentRef>

          <div *zuiToggleContentItem>
            <span class="__toggle-content-text">
              {{ state.appBarTranslations?.clickOr }} <zui-key-chip class="__key-chip">ctrl+s</zui-key-chip> {{ state.appBarTranslations?.toSearch }}
            </span>
          </div>

          <mat-icon
            *zuiToggleContentItem
            class="__search-icon">
            search
          </mat-icon>

        </zui-toggle-content>
      </button> -->

      <!-- <div class="__app-bar-action-button __notifications">
        <zui-notification-pop-trigger
          [matTooltip]="'Open notifications'"
          [unreadCount]="state.unreadNotificationsCount"
          [triggerRef]="notificationPopRef"
          (opened)="onOpenNotifications$.next()">
        </zui-notification-pop-trigger>
      </div> -->

      <!-- <div class="__user-button-wrap">
        <ng-container *ngIf="state.clientUser">

          <zef-avatar
            class="__client-avatar"
            [size]="22"
            [name]="state.clientUser.client?.accountName"
            [image]="state.clientUser.client?.avatar?.smallAvatarUrl"
            [borderWidth]="1"
            [backgroundColor]="'#000'">
          </zef-avatar>

          <button
            type="button"
            matRipple
            class="__user-button"
            (click)="userPopRef.open()"
            [satPopoverAnchor]="userPopRef">
            <zef-avatar
              class="__user-avatar"
              [size]="40"
              [name]="state.clientUser.user?.fullName"
              [email]="state.clientUser.user?.email"
              [image]="state.clientUser?.user?.avatar?.smallAvatarUrl"
              [externalImage]="state.clientUser?.user?.avatar?.externalAvatarUrl"
              [backgroundColor]="'#4c575c'">
            </zef-avatar>
          </button>

        </ng-container>
      </div> -->

    <!-- </div> -->

  <!-- </div> -->
</zef-fade-animation>

<!-- user info pop -->
<!-- <sat-popover
  #userPopRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  (afterClose)="userInfoAccountsRef.switchMode('user', true)"
  [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
  (backdropClicked)="userPopRef.close()"
  [verticalAlign]="'start'"
  [horizontalAlign]="'center'">
  <mat-card class="__user-menu-card z-manual-elevation c-soft-elevation">

  </mat-card>
</sat-popover> -->

<sat-popover
  #menuPopRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
  (backdropClicked)="menuPopRef.close()"
  [verticalAlign]="'start'"
  [horizontalAlign]="'start'">
  <mat-card
    class="__menu-card z-manual-elevation c-soft-elevation"
    [class.is-dark-theme]="state.isDarkTheme">
    <zef-scrollbar
      class="__scrollbar"
      maxHeight="calc(100vh - 80px)">

      <div class="__user-org fx:pb-1">
        @if (state.clientUser) {
          <div class="__user-org-grid">

            <div class="__user-org_avatars">
              <zef-avatar
                class="__user-org_avatar-org"
                [size]="22"
                [name]="state.clientUser.client?.accountName"
                [image]="state.clientUser.client?.avatar?.smallAvatarUrl"
                [borderWidth]="1"
                [backgroundColor]="'#000'">
              </zef-avatar>
              <zef-avatar
                [size]="40"
                class="__user-org_avatar-user"
                [name]="state.clientUser.user?.fullName"
                [email]="state.clientUser.user?.email"
                [image]="state.clientUser?.user?.avatar?.smallAvatarUrl"
                [externalImage]="state.clientUser?.user?.avatar?.externalAvatarUrl"
                [backgroundColor]="'#4c575c'">
              </zef-avatar>
            </div>

            <div class="__user-org_names">
              <h4 class="__user-org_name">
                {{ state.clientUser?.user?.firstName | middleEllipsis : 30 }}
                <span class="__user-org_mail">{{ state.clientUser?.user?.email }}</span>
              </h4>
              <h5 class="__user-org-org">{{ state.clientUser.client?.accountName }}</h5>
            </div>

            <div class="fx:text-right">

              @if (state.activeUserClientUsers?.length > 1) {
                <button
                  [satPopoverAnchor]="clientUserSelectPopRef?.popRef"
                  (click)="clientUserSelectPopRef?.popRef.open()"
                  mat-flat-button
                  class="__user-org-switch-button  c-button--smaller  fx:mr-0-5">
                  <mat-icon fontSet="material-icons-outlined">change_circle</mat-icon>
                  Switch Organization
                </button>
              }

              <button
                (click)="onLogout$.next(); menuPopRef.close();"
                mat-flat-button
                class="__logout-button c-button--smaller">
                <mat-icon fontSet="material-icons-outlined">logout</mat-icon> Logout
              </button>

            </div>

          </div>
        }

      </div>

      <zui-separator
        class="__menu-separator"
        [position]="'start'"
        [spacing]="'small'"
        [size]="'small'"
      />

      <div class="fx:grid fx:grid-cols-2 fx:col-gap-bu1">

        <div>

          <h5 class="__menu-category-title">Organization</h5>

          <div class="__menu-category-wrap">

            <div class="__menu-quick-action-list">

              <mat-nav-list>

                <a
                  (click)="menuPopRef.close()"
                  [routerLinkActive]="'is-active'"
                  [routerLink]="[ '/', 'dashboard', 'projects' ]"
                  class="__menu-mat-list-item"
                  mat-list-item>
                  <zui-action-list-item
                    class="__menu-list-item"
                    [icon]="'dashboard'"
                    [label]="'Projects'">
                  </zui-action-list-item>
                </a>

              </mat-nav-list>

              <div class="__menu-quick-buttons fx:inline-grid fx:grid-auto-flow-column fx:col-gap-1 fx:align-items-center">

                <div>
                  <a
                    (click)="menuPopRef.close()"
                    [routerLink]="[ '/', 'dashboard', 'project-add' ]"
                    mat-flat-button
                    class="__quick-add-button  c-button--smaller">
                    <mat-icon>add</mat-icon> Add
                  </a>
                </div>

                <div>
                  <button
                    (click)="onImportProject$.next();menuPopRef.close()"
                    mat-flat-button
                    class="__quick-add-button  c-button--smaller">
                    <mat-icon fontSet="material-icons-outlined">file_upload</mat-icon> Import
                  </button>
                </div>

              </div>

            </div>

            <div class="__menu-quick-action-list">

              <mat-nav-list>

                <a
                  (click)="menuPopRef.close()"
                  [routerLinkActive]="'is-active'"
                  [routerLink]="[ '/', 'dashboard', 'users' ]"
                  class="__menu-mat-list-item"
                  mat-list-item>
                  <zui-action-list-item
                    class="__menu-list-item"
                    [icon]="'people_outline'"
                    [label]="'Team'">
                  </zui-action-list-item>
                </a>

              </mat-nav-list>

              <div class="__menu-quick-buttons">

                <div>
                  <a
                    (click)="menuPopRef.close()"
                    [routerLink]="[ '/', 'dashboard', 'user-add' ]"
                    mat-flat-button
                    class="__quick-add-button  c-button--smaller">
                    <mat-icon>add</mat-icon> Add
                  </a>
                </div>

              </div>

            </div>

            <mat-nav-list>

              <a
                (click)="menuPopRef.close()"
                [routerLinkActive]="'is-active'"
                [routerLink]="[ '/', 'dashboard', 'finances' ]"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'monetization_on'"
                  [label]="'Credit & Spend Overview'">
                </zui-action-list-item>
              </a>

              <a
                (click)="menuPopRef.close()"
                [routerLinkActive]="'is-active'"
                [routerLink]="[ '/', 'settings', 'company' ]"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'business'"
                  [label]="'Organization Settings'">
                </zui-action-list-item>
              </a>

              <a
                (click)="menuPopRef.close()"
                [routerLinkActive]="'is-active'"
                [routerLink]="[ '/', 'settings', 'billing' ]"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'receipt_long'"
                  [label]="'Invoices & Billing Settings'">
                </zui-action-list-item>
              </a>

            </mat-nav-list>

          </div>

          <zui-separator
            class="__menu-mid-separator"
            [position]="'start'"
            [orientation]="'horizontal'"
            [spacing]="'medium'"
            [size]="'large'"
          />

          <h5 class="__menu-category-title">Interface</h5>

          <div class="__menu-category-wrap __menu-category-wrap--interface">

            <div>
              <button
                class="__menu-theme-button  c-button--smaller"
                [class.is-active]="!state.isZen"
                mat-button
                (click)="toggleZen()"
                type="button">
                <mat-icon fontSet="material-icons-outlined">{{ !state.isZen ? 'hide_source' : 'help_outline' }}</mat-icon>&nbsp;
                {{ !state.isZen ? 'Hide inline help' : 'Show inline help' }}
              </button>
            </div>

            <div>
              <zui-theme-selector
                [isDarkTheme]="state.isDarkTheme"
                (setTheme)="setTheme($event)"
                [activeMode]="state.activeTheme"
                [lightMode]="state.isLightMode">
              </zui-theme-selector>
            </div>

            <div>
              <button
                mat-button
                class="__menu-theme-button c-button--smaller"
                (click)="toggleCompact()"
                type="button">
                <mat-icon fontSet="material-icons-outlined">{{ state.isCompact ? 'view_compact' : 'view_compact_alt' }}</mat-icon>&nbsp;
                {{ !state.isCompact ? 'Switch to compact mode' : 'Switch to full mode' }}
              </button>
            </div>

          </div>

        </div>

        <div>

          <h5 class="__menu-category-title">User</h5>

          <div class="__menu-category-wrap">
            <mat-nav-list>

              <a
                (click)="menuPopRef.close()"
                [routerLinkActive]="'is-active'"
                [routerLink]="[ '/', 'settings', 'account' ]"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'person'"
                  [label]="'Account & Password settings'">
                </zui-action-list-item>
              </a>

              <a
                (click)="menuPopRef.close()"
                [routerLinkActive]="'is-active'"
                [routerLink]="[ '/', 'settings', 'token-management' ]"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'vpn_key'"
                  [label]="'Access Tokens management'">
                </zui-action-list-item>
              </a>

            </mat-nav-list>

          </div>

          <zui-separator
            class="__menu-mid-separator"
            [position]="'start'"
            [orientation]="'horizontal'"
            [spacing]="'medium'"
            [size]="'large'"
          />

          <h5 class="__menu-category-title">Resources</h5>

          <div class="__menu-category-wrap">
            <mat-nav-list>

              <a
                [href]="'https://docs.zerops.io'"
                target="_blank"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'text_snippet '"
                  [label]="'Documentation'">
                </zui-action-list-item>
              </a>

              <a
                (click)="menuPopRef.close()"
                [routerLinkActive]="'is-active'"
                [routerLink]="[ '/', 'dashboard', 'recipes' ]"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'auto_awesome'"
                  [label]="'Recipes'">
                </zui-action-list-item>
              </a>

              <a
                [href]="'https://discord.gg/WDvCZ54'"
                target="_blank"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'question_answer'"
                  [label]="'Discord - community help'">
                </zui-action-list-item>
              </a>

              <a
                [href]="'mailto:support@zerops.io'"
                target="_blank"
                class="__menu-mat-list-item"
                mat-list-item>
                <zui-action-list-item
                  class="__menu-list-item"
                  [icon]="'alternate_email'"
                  [label]="'Support email'">
                </zui-action-list-item>
              </a>

            </mat-nav-list>
          </div>

        </div>

        <!-- <div>
          <z-menu-panel (menuClicked)="menuPopRef.close()"></z-menu-panel>
        </div> -->

        <!-- <div>

          <z-user-info-accounts
            #userInfoAccountsRef
            (selectClientUser)="menuPopRef.close();onSelectClientUser$.next($event);"
            (logout)="onLogout$.next();menuPopRef.close()"
            (accountSettingsClicked)="menuPopRef.close()"
            (companySettingsClicked)="menuPopRef.close()"
            (billingClicked)="menuPopRef.close()"
            (tokenManagementClicked)="menuPopRef.close()"
            [clientUsers]="activeUserClientUsers$ | async"
            [activeClientUser]="clientUser$ | async"
            [accountSettingsTranslation]="!(state.activeUser?.status === userStatuses.Active && !state.activeUser?.passwordIsSet)
              ? state.appBarTranslations?.accountPasswordSettings
              : state.appBarTranslations?.accountSettings"
            [tokenManagementText]="state.appBarTranslations?.tokenManagement"
            [billingText]="state.appBarTranslations?.billing"
            [isContabo]="state.isContabo"
            [companySettingsTranslation]="state.appBarTranslations?.company"
            [logOutTranslation]="state.appBarTranslations?.logOut">
          </z-user-info-accounts>

          <div>
            <button
              mat-icon-button
              (click)="toggleZen()"
              type="button"
              [matTooltip]="'Turn on/off tips'">
              <mat-icon fontSet="material-icons-outlined">{{ state.isZen ? 'hide_source' : 'help_outline' }}</mat-icon>
            </button>
          </div>

          <div>
            <zui-theme-selector
              [isDarkTheme]="state.isDarkTheme"
              [matTooltip]="'Select theme'"
              (setTheme)="setTheme($event)"
              [activeMode]="state.activeTheme"
              [lightMode]="state.isLightMode">
            </zui-theme-selector>
          </div>

          <div>
            <button
              mat-icon-button
              (click)="toggleCompact();menuPopRef.close();"
              type="button"
              [matTooltip]="'Turn on/off tips'">
              <mat-icon fontSet="material-icons-outlined">{{ state.isCompact ? 'view_compact' : 'view_compact_alt' }}</mat-icon>
            </button>
          </div>

        </div> -->

      </div>

    </zef-scrollbar>
  </mat-card>
</sat-popover>
