import { createAction, union } from '@ngrx/store';
import {
  zefActionFailPayload,
  zefActionPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';
import {
  AddonsResponse,
  BillingPaymentRequestDto,
  BillingPaymentResponse,
  BillingPaymentSourcesResponse
} from '@zerops/models/billing';

export const paymentRequest = createAction(
  '[@zerops/zerops/core/billing-base] payment-request',
  zefActionPayload<BillingPaymentRequestDto>()
);

export const paymentRequestFail = createAction(
  '[@zerops/zerops/core/billing-base] payment-request/fail',
  zefActionFailPayload
);

export const paymentRequestSuccess = createAction(
  '[@zerops/zerops/core/billing-base] payment-request/success',
  zefActionSuccessPayload<BillingPaymentResponse>()
);

export const confirmPayment = createAction(
  '[@zerops/zerops/core/billing-base] confirm-payment',
  zefActionPayload<any>()
);

export const confirmPaymentFail = createAction(
  '[@zerops/zerops/core/billing-base] confirm-payment/fail',
  zefActionFailPayload
);

export const confirmPaymentSuccess = createAction(
  '[@zerops/zerops/core/billing-base] confirm-payment/success',
  zefActionSuccessPayload()
);

export const paymentSources = createAction(
  '[@zerops/zerops/core/billing-base] payment-sources',
  zefActionPayload()
);

export const paymentSourcesFail = createAction(
  '[@zerops/zerops/core/billing-base] payment-sources/fail',
  zefActionFailPayload
);

export const paymentSourcesSuccess = createAction(
  '[@zerops/zerops/core/billing-base] payment-sources/success',
  zefActionSuccessPayload<BillingPaymentSourcesResponse>()
);

export const removePaymentSource = createAction(
  '[@zerops/zerops/core/billing-base] remove-payment-source',
  zefActionPayload<string>()
);

export const removePaymentSourceFail = createAction(
  '[@zerops/zerops/core/billing-base] remove-payment-source/fail',
  zefActionFailPayload
);

export const removePaymentSourceSuccess = createAction(
  '[@zerops/zerops/core/billing-base] remove-payment-source/success',
  zefActionSuccessPayload<{ success: boolean; }>()
);

export const availableAddons = createAction(
  '[@zerops/zerops/core/billing-base] available-addons',
  zefActionPayload()
);

export const availableAddonsFail = createAction(
  '[@zerops/zerops/core/billing-base] available-addons/fail',
  zefActionFailPayload
);

export const availableAddonsSuccess = createAction(
  '[@zerops/zerops/core/billing-base] available-addons/success',
  zefActionSuccessPayload<AddonsResponse>()
);

export const modifyAddon = createAction(
  '[@zerops/zerops/core/billing-base] modify-addon',
  zefActionPayload<{ id: string; recurringEnabled: boolean; enabled: boolean }>()
);

export const modifyAddonFail = createAction(
  '[@zerops/zerops/core/billing-base] modify-addon/fail',
  zefActionFailPayload
);

export const modifyAddonSuccess = createAction(
  '[@zerops/zerops/core/billing-base] modify-addon/success',
  zefActionSuccessPayload<{ success: boolean; }>()
);

const all = union({
  paymentRequest,
  paymentRequestFail,
  paymentRequestSuccess,
  confirmPayment,
  confirmPaymentFail,
  confirmPaymentSuccess,
  paymentSources,
  paymentSourcesFail,
  paymentSourcesSuccess,
  removePaymentSource,
  removePaymentSourceFail,
  removePaymentSourceSuccess,
  availableAddons,
  availableAddonsFail,
  availableAddonsSuccess,
  modifyAddon,
  modifyAddonSuccess,
  modifyAddonFail
});

export type BillingBaseActionUnion = typeof all;
