<zui-form-section
  [isZen]="isZen"
  [separator]="'none'">

  <span *zuiFormSectionTitle>
    {{ translations.hostnameTitle }}
  </span>

  <div *zuiFormSectionDesc>
    <zui-docs-text>
      <span [innerHTML]="translations?.hostnameDesc | safeHtml"></span>
    </zui-docs-text>
  </div>

  <zef-form-field last>

    <z-hostname-field
      [label]="translations.hostnameLabel"
      [placeholder]="translations.hostnamePlaceholder"
      [control]="state?.controls.name">
    </z-hostname-field>

  </zef-form-field>

</zui-form-section>

<zui-separator
  [orientation]="'vertical'"
  [size]="'medium'"
  [spacing]="'huge'"
  [position]="'center'"
/>

<zui-form-section
  [isZen]="isZen"
  [separator]="'none'"
  class="__scaling-section">

  <span *zuiFormSectionTitle>
    Automatic Scaling
  </span>

  <div *zuiFormSectionDesc>
    <zui-docs-text>

      <ng-container *ngIf="serviceStackType === serviceStackTypes.Elasticsearch">
        Zerops will scale the service horizontally by adding and removing containers (two load balancers are used to distribute the traffic) and
        vertically by increasing and decreasing each container's resources, depending on the current load and the scaling best practices.
        You can fine-tune all within the ranges to fit the exact need of the environment you plan to run the service.
        Find all the specifics in Zerops <a data-zui-docs="automatic-scaling/how-automatic-scaling-works.html">scaling documentation.</a>
      </ng-container>

      <ng-container *ngIf="serviceStackType === serviceStackTypes.RabbitMq">
        The cluster runs always on three containers and uses two load balancers to distribute the load, as this is the most efficient
        configuration for the vast majority of cases. Zerops will scale the service only vertically by increasing and decreasing each container's resources, depending on the current load and the scaling best practices.
        You can fine-tune all within the ranges to fit the exact need of the environment you plan to run the service.
        Find all the specifics in Zerops <a data-zui-docs="automatic-scaling/how-automatic-scaling-works.html">scaling documentation.</a>
      </ng-container>

      <ng-container *ngIf="serviceStackType === serviceStackTypes.Redis">
        The cluster runs always on three / two containers, as this is the most efficient configuration for the vast majority of cases. Zerops will scale the service only vertically by increasing and
        decreasing each container's resources, depending on the current load and the scaling best practices.
        You can fine-tune all within the ranges to fit the exact need of the environment you plan to run the service.
        Find all the specifics in Zerops <a data-zui-docs="automatic-scaling/how-automatic-scaling-works.html">scaling documentation.</a>
      </ng-container>

      <ng-container *ngIf="serviceStackType === serviceStackTypes.MongoDb">
        The cluster runs always on three / two containers, as this is the most efficient configuration for the vast majority of cases.
        Zerops will scale the service only vertically by increasing and decreasing each container's resources,
        depending on the current load and the scaling best practices. You can fine-tune all within the ranges to fit the exact
        need of the environment you plan to run the service. Find all the specifics in Zerops
        <a data-zui-docs="automatic-scaling/how-automatic-scaling-works.html">scaling documentation.</a>
      </ng-container>

      <ng-container *ngIf="serviceStackType === serviceStackTypes.MariaDb">
        The cluster runs always on three containers and uses two load balancers to distribute the load, as this is the most efficient
        configuration for the vast majority of cases. Zerops will scale the service only vertically by
        increasing and decreasing each container's resources, depending on the current load and the scaling best practices.
        You can fine-tune all within the ranges to fit the exact need of the environment you plan to run the service.
        Find all the specifics in Zerops <a data-zui-docs="automatic-scaling/how-automatic-scaling-works.html">scaling documentation.</a>
      </ng-container>

      <ng-container *ngIf="serviceStackType === serviceStackTypes.Postgres">
        The cluster runs always on three containers and uses two load balancers to distribute the load, as this is the most
        efficient configuration for the vast majority of cases. Zerops will scale the service only vertically by
        increasing and decreasing each container's resources, depending on the current load and the scaling best practices.
        You can fine-tune all within the ranges to fit the exact need of the environment you plan to run the service.
        Find all the specifics in Zerops <a data-zui-docs="automatic-scaling/how-automatic-scaling-works.html">scaling documentation.</a>
      </ng-container>

    </zui-docs-text>
  </div>

  <zef-form-field>
    <zui-autoscaling-form-field
      [serviceStackType]="serviceStackType"
      [ngrxFormControlState]="state?.controls.customAutoscaling"
      [defaultHorizontalAutoscaling]="defaultHorizontalAutoscaling"
      [defaultVerticalAutoscaling]="defaultVerticalAutoscaling"
    />
  </zef-form-field>

</zui-form-section>

<zui-separator
  [orientation]="'vertical'"
  [size]="'medium'"
  [spacing]="'large'"
  [position]="'center'"
/>
