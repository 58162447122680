import { HashMap } from '@zerops/zef/core';
import { Currency, Language } from '@zerops/models/settings';
import { Client } from '@zerops/models/client';
import { Subscription } from '@zerops/models/subscription';

export interface BillingPaymentRequestDto {
  amount: number;
  sourceId?: string;
}

export interface BillingPaymentResponse {
  clientSecret: string;
  paymentId: string;
  paymentStatus: string;
}

export interface BillingPaymentSourcesResponse {
  clientId: string;
  sources: PaymentSource[];
}

export interface PaymentSource {
  id: string;
  object: string;
  addressCity: string;
  addressCountry: string;
  addressLine1: string;
  addressLine1_check: string;
  addressLine2: string;
  addressState: string;
  addressZip: string;
  addressZipCheck: string;
  brand: string;
  country: string;
  cvcCheck: string;
  expMonth: string;
  expYear: string;
  funding: string;
  last4: string;
  name: string;
  tokenizationMethod: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  metadata: HashMap<any>;
}

export class ClientInfo {
  id: string;
  companyName: string;
  companyNumber: string;
  vatNumber: string;
  created: string;
  lastUpdate: string;
  lastPublicUpdate: string;
  status: string;
  vatCountry: string;
  vatPayer: boolean;
  vatRate: number;
  vatMode: string;
  currency: Currency;
  language: Language;
}

export class AddonsResponse {
  clientAddons: Addon[];
  projectAddons: Addon[];
}

export enum MetricIds {
  ProjectIpV4 = 'PROJECT_IPV4',
  ProjectCore = 'PROJECT_CORE',
  Disc = 'DISC',
  Cpu = 'CPU',
  Ram = 'RAM'
}

export class Addon {
  addonName: string;
  metricId: MetricIds;
  projectId: string;
  unitPrice: number;
  userEnabled: boolean;
  validFrom: string;
  validTill: string;
}

export interface GroupedAddon {
  metricId: string;
  subscriptions: SubscriptionExtendedByProjectInfo[];
  availableAddons: AddonExtendedByProjectInfo[];
}

export class SubscriptionExtendedByProjectInfo extends Subscription {
  projectName: string;
}

export class AddonExtendedByProjectInfo extends Addon {
  projectName: string;
}

// TODO: Discuss better Payment DTO format
export class Payment {
  id: string;
  created: string;
  lastUpdate: string;
  client: Client;
  clientId: string;
  status: PaymentStatuses;
  application: string;
  amount: number;
  amountVat: number;
  currency: Currency;
  datePaid: string;
  requestId: string;
  paymentMethod: string;
  paymentObject: PaymentObject;
  paymentProvider: string;
  interactionType: 'ONSESSION' | 'OFFSESSION';
}

export class PaymentObject {
  charges: ChargeObject;
  metadata: {
    invoiceId: string;
    vat_rate: string;
  }
}

export class ChargeObject {
  data: ChargeData[];
}

export class ChargeData {
  payment_method_details: {
    card: {
      brand: string;
      exp_year: number;
      exp_month: number;
      last4: string;
    }
  }
}

export class Invoice {
  id: string;
  created: string;
  lastUpdate: string;
  number: string;
  // TODO: InvoiceStatuses
  status: string;
  client: Client;
  clientAddress: Address;
  contractor: Contractor;
  contractorAddress: Address;
  dueDate: string;
  total: number;
  vatRate: number;
  vatAmount: number;
  currency: Currency;
  totalVat: number;
  paidDate: string;
  totalDue: number;
  invoiceItems: InvoiceItem[];
}

export class InvoiceItem {
  id: string;
  itemName: string;
  amount: number;
  unitPrice: number;
  total: number;
  vatRate: number;
  isReverseCharged: boolean;
}

export class Address {
  city: string;
  companyName: string;
  countryId: string;
  postcode: string;
  street: string;
}

export class Contractor {
  id: string;
  companyName: string;
  isActive: boolean;
  companyNumber: string;
  vatNumber: string;
  created: string;
  lastUpdate: string;
  invoiceAddress: Address;
  invoiceSerieNumber: number;
}

export enum PaymentStatuses {
  Requested = 'REQUESTED',
  Processing = 'PROCESSING',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  PaymentDeclined = 'PAYMENT_DECLINED',
  BalanceUpdating = 'BALANCE_UPDATING',
  balanceUpdated = 'BALANCE_UPDATED',
  Invoicing = 'INVOICING',
  Invoiced = 'INVOICED',
  PaymentFailed = 'PAYMENT_FAILED',
  BalanceUpdateFailed = 'BALANCE_UPDATE_FAILED',
  InvoicingFailed = 'INVOICING_FAILED',
  Finished = 'FINISHED',
  PaymentDeclineFailed = 'PAYMENT_DECLINE_FAILED',
  Failed = 'FAILED'
}
