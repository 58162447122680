import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { DialogErrorContainer } from './dialog-error.container';

@NgModule({
  declarations: [ DialogErrorContainer ],
  imports: [
    CommonModule,
    MaterialStackModule
  ],
  exports: [ DialogErrorContainer ]
})
export class DialogErrorModule {

}
