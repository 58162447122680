import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { firstAvailable } from '@zerops/zef/core';
import { zefDialogClose } from '@zerops/zef/dialog';
import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onWebsocketMessageDispatchUpdateEntities
} from '@zerops/zef/entities';
import { ZefSnackService } from '@zerops/zef/snack';
import { ADDON_ACTIVATION_DIALOG_FEATURE_NAME } from '@zerops/zerops/feature/addon-activation-dialog';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap
} from 'rxjs/operators';
import { billingInfo } from '../client-base';
import { PaymentEntity } from '../payment-base';
import { loadCountryList } from '../settings-base';
import { UserEntity } from '../user-base';
import {
  availableAddons,
  BillingBaseActionUnion,
  paymentRequest, paymentRequestFail,
  paymentRequestSuccess,
  paymentSources,
  paymentSourcesFail,
  paymentSourcesSuccess,
  availableAddonsFail,
  availableAddonsSuccess,
  removePaymentSource,
  removePaymentSourceSuccess,
  removePaymentSourceFail,
  modifyAddon,
  modifyAddonSuccess,
  modifyAddonFail
} from './billing-base.action';
import { BillingBaseApi } from './billing-base.api';

@Injectable()
export class BillingBaseEffect {

  private _activeClientId$ = this._userEntity.activeClientId$.pipe(filter((d) => !!d));

  private _onPaymentRequestInfo$ = createEffect(() => this._actions$.pipe(
    ofType(paymentRequest),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this._api
        .paymentRequest$(
          action.data,
          clientId
        )
        .pipe(
          map((res) => paymentRequestSuccess(res, action)),
          catchError((err) => of(paymentRequestFail(err, action)))
        )
      )
    )
  )));

  private _onRemovePaymentSource$ = createEffect(() => this._actions$.pipe(
    ofType(removePaymentSource),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this._api
        .removePaymentSource$(clientId, action.data)
        .pipe(
          map((res) => removePaymentSourceSuccess(res, action)),
          catchError((err) => of(removePaymentSourceFail(err, action)))
        )
      )
    ))
  ));

  private _onRemovePaymentSourceSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(removePaymentSourceSuccess),
    switchMap(() => this._snack.success$({ text: 'Card was removed successfully' }))
  ), { dispatch: false });

  private _onPaymentSources$ = createEffect(() => this._actions$.pipe(
    ofType(paymentSources),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this._api
        .paymentSources$(clientId)
        .pipe(
          map((res) => paymentSourcesSuccess(res, action)),
          catchError((err) => of(paymentSourcesFail(err, action)))
        )
      )
    )
  )));

  private _onActiveClientIdLoadAvailableAddons$ = createEffect(() => this._activeClientId$.pipe(
     map(() => availableAddons())
  ));

  private _onAvailableAddonsRequest$ = createEffect(() => this._actions$.pipe(
    ofType(availableAddons),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this._api
        .availableAddons$(clientId)
        .pipe(
          map((res) => availableAddonsSuccess(res, action)),
          catchError((err) => of(availableAddonsFail(err, action)))
        )
      )
    )
  )));

  private _onModifyAddon$ = createEffect(() => this._actions$.pipe(
    ofType(modifyAddon),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this._api
        .modifyAddon$(action.data?.id, clientId, action.data?.recurringEnabled, action.data?.enabled)
        .pipe(
          map((res) => modifyAddonSuccess(res, action)),
          catchError((err) => of(modifyAddonFail(err, action)))
        )
      )
    )
  )));

  private _onModifyAddonSuccess$ = this._actions$.pipe(
    ofType(modifyAddonSuccess)
  );

  private _onModifyAddonSuccessLoadAvailableAddons$ = createEffect(() => this._onModifyAddonSuccess$.pipe(
    map(() => availableAddons())
  ));

  private _onModifyAddonSuccessCloseDialog$ = createEffect(() => this._onModifyAddonSuccess$.pipe(
    map(() => zefDialogClose({ key: ADDON_ACTIVATION_DIALOG_FEATURE_NAME }))
  ));

  private _onActiveClientIdLoadCountryList$ = createEffect(() => this._activeClientId$.pipe(
    map(() => loadCountryList())
  ));

  private _onActiveClientIdLoadBillingInfo$ = createEffect(() => this._activeClientId$.pipe(
    map(() => billingInfo())
  ));

  // payment list / update
  private _setupPaymentListStream$ = createEffect(() => this._activeClientId$.pipe(
    map((clientId) => this._paymentEntity.listSubscribe(
      clientId
    ))
  ));

  private _onPaymentAddRemoveMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this._paymentEntity
    )
  ));

  private _setupUpdateStreamSubscription$ = createEffect(() => this._activeClientId$.pipe(
    map((clientId) => this._paymentEntity.updateSubscribe(clientId)
  )));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._paymentEntity)
  ));

  constructor(
    private _userEntity: UserEntity,
    private _paymentEntity: PaymentEntity,
    private _actions$: Actions<BillingBaseActionUnion>,
    private _api: BillingBaseApi,
    private _snack: ZefSnackService
  ) { }

}
