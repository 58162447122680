import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  filter,
  withLatestFrom,
  pairwise,
  mergeMap,
  take
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  onWebsocketMessageDispatchUpdateEntities,
  MergeStrategy,
  onWebsocketMessageDispatchAddRemoveEntities,
  successOf,
  errorOf,
  getSubscriptionNameForFeature,
  selectEntityList
} from '@zerops/zef/entities';
import {
  eachHourOfInterval,
  subHours,
  addHours,
  subMinutes
} from 'date-fns/esm';
import { Project } from '@zerops/models/project';
import { deletionWarningDialogOpen } from '@zerops/zerops/feature/deletion-warning-dialog';
import { ADDON_ACTIVATION_DIALOG_FEATURE_NAME } from '@zerops/zerops/feature/addon-activation-dialog';
import {
  ImportExportDialogModes,
  importExportDialogOpen,
  IMPORT_EXPORT_DIALOG_FEATURE_NAME
} from '@zerops/zerops/feature/import-export-dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import { ofRoute, selectZefNgrxRouterIdByKey, zefGo } from '@zerops/zef/ngrx-router';
import { ApiEntityKeys, AppState, RouteKeys } from '@zerops/zerops/app';
import { ZefSnackService } from '@zerops/zef/snack';
import { requestShowYaml } from '@zerops/zui/recipes/recipes.action';
import { onWebsocketSubscriptionName } from '@zerops/zef/websocket';
import { UserEntity } from '../user-base';
import { ProjectEntity } from './project-base.entity';
import {
  loadProjectTags,
  loadProjectTagsSuccess,
  loadProjectTagsFail,
  requestIPv4Success,
  requestIPv4,
  requestIPv4Fail,
  startProject,
  startProjectSuccess,
  startProjectFail,
  projectExport,
  projectExportSuccess,
  projectExportFail,
  projectImport,
  projectImportSuccess,
  projectImportFail,
  updateRemoteLogging,
  updateRemoteLoggingSuccess,
  updateRemoteLoggingFail,
  addVpnPublicKey,
  addVpnPublicKeySuccess,
  addVpnPublicKeyFail,
  deleteVpnPublicKey,
  deleteVpnPublicKeySuccess,
  deleteVpnPublicKeyFail,
  listVpnPublicKeys,
  listVpnPublicKeysSuccess,
  listVpnPublicKeysFail
} from './project-base.action';
import { ProjectBaseApi } from './project-base.api';
import { FEATURE_NAME } from './project-base.constant';
import { availableAddons } from '../billing-base';
import { select, Store } from '@ngrx/store';
import difference from 'lodash-es/difference';
import { transactionDebitGroupPrefillData } from '@zerops/zerops/core/transaction-debit-base';
import {
  TransactionDebitGroupItem,
  TransactionGroupBy,
  TRANSACTION_GROUP_RANGE
} from '@zerops/models/transaction-debit';
import { log } from '@zerops/zef/core';

@Injectable()
export class ProjectBaseEffect {

  private _clientId$ = this._userEntity.activeClientId$;

  private _onLoadTags$ = createEffect(() => this._actions$.pipe(
    ofType(loadProjectTags),
    switchMap((action) => this._api
      .loadTags$(action.data)
      .pipe(
        map((res) => loadProjectTagsSuccess(res.items, action)),
        catchError((err) => of(loadProjectTagsFail(err, action)))
      )
    )
  ));

  private _setupListStreamSubscription$ = createEffect(() => this._clientId$.pipe(
    map((clientId) => this._projectEntity.listSubscribe(
      clientId,
      FEATURE_NAME,
      undefined,
      {
        handleGlobally: false
      }
    ))
  ));

  private _prefillTransactionDebitForProjects$ = createEffect(() => this._store.pipe(
    // we only need ids, so we are taking them raw from the state
    select(selectEntityList(this._projectEntity.entityName)),
    pairwise(),
    // checking if there are new ids
    map(([ prevIds, currIds ]) => difference(currIds, prevIds)),
    mergeMap((ids: string[]) => {

      const groupRange = TRANSACTION_GROUP_RANGE.last24h;

      const items = ids.reduce((arr: any, id: string) => {

        arr.push(...eachHourOfInterval({
          start: addHours(groupRange.range.from, 1),
          end: groupRange.range.to
        }).map((itm: Date) => ({
          sumTotalPrice: 0,
          from: subHours(itm, 1),
          projectId: id,
          till: subMinutes(itm, 1)
        })));

        return arr;

      }, []) as TransactionDebitGroupItem[];

      return [
        transactionDebitGroupPrefillData({
          timeGroupBy: groupRange.timeGroupBy,
          groupBy: TransactionGroupBy.Project,
          limit: groupRange.limit,
          from: groupRange.range.from,
          till: groupRange.range.to,
          key: groupRange.key,
          items
        }),
        transactionDebitGroupPrefillData({
          timeGroupBy: groupRange.timeGroupBy,
          groupBy: TransactionGroupBy.Service,
          limit: groupRange.limit,
          from: groupRange.range.from,
          till: groupRange.range.to,
          key: groupRange.key,
          items
        })
      ];

    })
  ));

  private _setupAddRemoveMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this._projectEntity),
  ));

  private _setupUpdateStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._projectEntity.updateSubscribe(clientId),
  )));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(
      this._projectEntity,
      {
        zefEntityMergeStrategy: {
          firewallIpRanges: MergeStrategy.KeepNew
        }
      }
    )
  ));

  private _onRequestIpv4$ = createEffect(() => this._actions$.pipe(
    ofType(requestIPv4),
    switchMap((action) => this._api
      .requestIpv4$(action.data)
      .pipe(
        map((res) => requestIPv4Success(res, action)),
        catchError((res) => of(requestIPv4Fail(res, action)))
      )
    )
  ));

  private _onProjectAddSuccessLoadAvailableAddons$ = createEffect(() => this._actions$.pipe(
    successOf<{ success: boolean; }>(this._projectEntity.addOne),
    map(() => availableAddons())
  ));

  private _onRequestIpv4Success$ = this._actions$.pipe(
    ofType(requestIPv4Success)
  );

  private _onRequestIpv4SuccessLoadAvailableAddons$ = createEffect(() => this._onRequestIpv4Success$.pipe(
    map(() => availableAddons())
  ));

  private _onRequestIpv4SuccessCloseAddonDialog$ = createEffect(() => this._onRequestIpv4Success$.pipe(
    map(() => zefDialogClose({ key: ADDON_ACTIVATION_DIALOG_FEATURE_NAME }))
  ));

  private _onStartProject$ = createEffect(() => this._actions$.pipe(
    ofType(startProject),
    switchMap((action) => this._api
      .startProject$(action.data.id)
      .pipe(
        map((res) => startProjectSuccess(res, action)),
        catchError((res) => of(startProjectFail(res, action)))
      )
    )
  ));

  private _onGetOneError$ = createEffect(() => this._actions$.pipe(
    errorOf<Project>(this._projectEntity.getOne),
    filter(({ meta: { zefError } }) => zefError.code === 'projectNotFound'),
    map(() => deletionWarningDialogOpen({ entity: ApiEntityKeys.Project }))
  ));

  private _onUpdateSuccess$ = this._actions$.pipe(
    successOf<Project>(this._projectEntity.updateOne)
  );

  private _onProjectUpdateSuccessNotification$ = createEffect(() => this._onUpdateSuccess$.pipe(
    filter((action) => !action.originalAction?.meta?.tag),
    switchMap(() => this._snack.success$({ text: 'Project was updated successfully' }))
  ), { dispatch: false });

  private _onUpdateSuccessNotification$ = createEffect(() => this._onUpdateSuccess$.pipe(
    filter((action) => action.originalAction?.meta?.tag === 'limit'),
    switchMap(() => this._snack.success$({ text: 'Project daily cost limit was updated successfully' }))
  ), { dispatch: false });

  private _onRemoveLimitSuccessNotification$ = createEffect(() => this._onUpdateSuccess$.pipe(
    filter((action) => action.originalAction?.meta?.tag === 'limit_remove'),
    switchMap(() => this._snack.success$({ text: 'Project daily cost limit was removed successfully' }))
  ), { dispatch: false });

  private _onProjectExport$ = createEffect(() => this._actions$.pipe(
    ofType(projectExport),
    switchMap((action) => this._api
      .projectExport$(action.data)
      .pipe(
        map((res) => projectExportSuccess(res.yaml, action)),
        catchError((res) => of(projectExportFail(res, action)))
      )
    )
  ));

  private _onProjectExportSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(projectExportSuccess),
    map(({ data }) => importExportDialogOpen({
      yaml: data,
      mode: ImportExportDialogModes.Export
    }))
  ));

  private _onProjectImport$ = createEffect(() => this._actions$.pipe(
    ofType(projectImport),
    withLatestFrom(this._clientId$),
    mergeMap(([ action, clientId ]) => this._api
      .projectImport$(action.data, clientId)
      .pipe(
        map((res) => projectImportSuccess(res.yaml, action)),
        catchError((res) => of(projectImportFail(res, action)))
      )
    )
  ));

  private _onProjectImportSuccess$ = this._actions$.pipe(
    ofType(projectImportSuccess)
  );

  private _onProjectImportSuccessRedirect$ = createEffect(() => this._onProjectImportSuccess$.pipe(
    filter(({ originalAction }) => !!originalAction.meta?.redirect),
    log('redcus'),
    map(({ originalAction }) => zefGo(originalAction.meta?.redirect))
  ));

  private _onProjectImportSuccessDialogClose$ = createEffect(() => this._onProjectImportSuccess$.pipe(
    map(() => zefDialogClose({
      key: IMPORT_EXPORT_DIALOG_FEATURE_NAME
    }))
  ));

  private _onRequestShowYaml$ = createEffect(() => this._actions$.pipe(
    ofType(requestShowYaml),
    map(({ yaml }) => importExportDialogOpen({ mode: ImportExportDialogModes.Import, yaml }))
  ));

  private _listSubscription$ = createEffect(() => this._actions$.pipe(
    onWebsocketSubscriptionName(getSubscriptionNameForFeature(
      ApiEntityKeys.Project,
      'list'
    )),
    filter((message) => message.data.add && message.data.add.length),
    map(() => availableAddons())
  ));

  private _onUpdateRemoteLoggingRequest$ = createEffect(() => this._actions$.pipe(
    ofType(updateRemoteLogging),
    switchMap((action) => this._api
      .updateRemoteLogging$(action.data.id, action.data.payload)
      .pipe(
        map((res) => updateRemoteLoggingSuccess(res, action)),
        catchError((res) => of(updateRemoteLoggingFail(res, action)))
      )
    )
  ));

  /**
   * Is invoked by the action to add a new WireGuard VPN public key.
   */
  onAddVpnPublicKeyRequest$ = createEffect(() => this._actions$.pipe(
    ofType(addVpnPublicKey),
    switchMap((action) => this._api
      .addVpnPublicKey$(action.data.id, action.data.payload)
      .pipe(
        map((res) => addVpnPublicKeySuccess(res, action)),
        catchError((res) => of(addVpnPublicKeyFail(res, action)))
      )
    )
  ));

  /**
   * Is invoked by the action to delete already existed WireGuard VPN public key.
   */
  onDeleteVpnPublicKeyRequest$ = createEffect(() => this._actions$.pipe(
    ofType(deleteVpnPublicKey),
    switchMap((action) => this._api
      .deleteVpnPublicKey$(action.data.id, action.data.payload)
      .pipe(
        map((res) => deleteVpnPublicKeySuccess(res, action)),
        catchError((res) => of(deleteVpnPublicKeyFail(res, action)))
      )
    )
  ));

  /**
   * Is invoked by the action to list already existed WireGuard VPN public keys.
   */
  onListVpnPublicKeysRequest$ = createEffect(() => this._actions$.pipe(
    ofType(listVpnPublicKeys),
    switchMap((action) => this._api
      .listVpnPublicKeys$(action.data.id)
      .pipe(
        map((res) => listVpnPublicKeysSuccess(
          {
            id: action.data.id,
            response: res
          },
        action
        )),
        catchError((res) => of(listVpnPublicKeysFail(res, action)))
      )
    )
  ));

  /**
   * The successful addition or deletion of WireGuard VPN public keys invokes it.
   * The reason is to refresh data in the store because they are not updated via WS.
   */
  onVpnPublicKeysChangeRequest$ = createEffect(() => this._actions$.pipe(
    ofType(addVpnPublicKeySuccess, deleteVpnPublicKeySuccess),
    map((action) => listVpnPublicKeys({ id: action.originalAction.data.id }))
  ));

  /**
   * Used to initially load all registered WireGuard VPN public keys
   * when a user navigates to the project detail route.
   * This action is called also after each request for adding a new
   * or deleting existed public key to refresh the store.
   */
  onRoute$ = createEffect(() => this._actions$.pipe(
    ofRoute('project/:id/routing'),
    switchMap(() => this._store.pipe(
      select(selectZefNgrxRouterIdByKey(RouteKeys.ProjectDetail)),
      filter((d) => !!d),
      take(1)
    )),
    map((id) => listVpnPublicKeys({ id }))
  ));

  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _api: ProjectBaseApi,
    private _projectEntity: ProjectEntity,
    private _userEntity: UserEntity,
    private _snack: ZefSnackService
  ) {}
}
