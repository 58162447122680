import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NgIf, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatIconRegistry } from '@angular/material/icon';
import { Store, select } from '@ngrx/store';
import { ThemeService } from '@zerops/zef/theme';
import { interval, combineLatest } from 'rxjs';
import {
  map,
  audit,
  filter,
  distinctUntilChanged,
  tap,
  switchMap,
  takeUntil,
  withLatestFrom,
  delay,
} from 'rxjs/operators';
import uniq from 'lodash-es/uniq';
import {
  ZefAuthState,
  zefSelectAuthData,
  zefSelectAuthState,
  selectState as selectAuthWholeState
} from '@zerops/zef/auth';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefWebsocketReconnecting } from '@zerops/zef/websocket';
import { outerRouteAnimation } from '@zerops/zef/animations';
import { Intercom } from '@zerops/zef/intercom';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { CUSTOM_ICONS } from '@zerops/zef/shared-assets/custom-icons/custom-icons.constant';
import { ZefRecaptchaModule } from '@zerops/zef/recaptcha';
import { ZefErrorsModule } from '@zerops/zef/errors';
import { ZefSnackModule } from '@zerops/zef/snack';
import { SatPopoverModule } from '@zerops/zef/popover';
import { ZefPipesModule } from '@zerops/zef/pipes';
import { ZuiDocsPopModule } from '@zerops/zui/docs-pop';
import { WebNotificationsPopModule } from '@zerops/zui/web-notifications-pop';
import { WebServiceStackActiveAppVersionPopModule } from '@zerops/zui/web-service-stack-active-app-version-pop';
import { WebPublicRoutingPopModule } from '@zerops/zui/web-public-routing-pop';
import { ZuiContextualDialogModule } from '@zerops/zui/contextual-dialog';
import { FeatureModule } from '@zerops/zerops/feature';
import { ZeElementsModule } from '@zerops/zerops/elements';
import { environment } from '@zerops/zerops/env';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { ProjectEntity } from '@zerops/zerops/core/project-base';
import {
  WsReconnectTranslations,
  WS_RECONNECT_FEATURE_NAME
} from '@zerops/zerops/feature/ws-reconnect';
import { WsReconnectModule } from '../feature/ws-reconnect';
import { AppPermissionsModule } from './app.permissions';
import { WebsocketDebuggerModule } from '../core/websocket-debugger';
import { menuPanelAnimation } from './app.animations';
import { AppState } from './app.model';
import { en } from './app.constant';
import isEqual from 'lodash-es/isEqual';

registerLocaleData(localeCs, 'cs');

@Component({
  standalone: true,
  selector: 'z-app',
  templateUrl: './app.container.html',
  styleUrls: [ './app.container.scss' ],
  animations: [
    menuPanelAnimation(),
    outerRouteAnimation
  ],
  imports: [
    NgIf,
    RouterOutlet,
    FeatureModule,
    AppPermissionsModule,
    ZefRecaptchaModule,
    ZefErrorsModule,
    ZefSnackModule,
    ZeElementsModule,
    SatPopoverModule,
    MatCardModule,
    ZefPipesModule,
    MatProgressSpinnerModule,
    WsReconnectModule,
    ZuiDocsPopModule,
    WebsocketDebuggerModule,
    WebNotificationsPopModule,
    WebServiceStackActiveAppVersionPopModule,
    WebPublicRoutingPopModule,
    ZuiContextualDialogModule
  ]
})
export class AppContainer extends ZefReactiveComponent implements OnInit {
  // # Data
  // -- sync
  authStates = ZefAuthState;
  googleSiteKey = environment.googleSiteKey;
  isProduction = environment.production;
  hideVersionWarning = environment.hideVersionWarning === 'true';
  menuPanelOpen = false;
  envName = environment.envName;
  currentProductionEnvName = environment.currentProductionEnvName;
  documentationPath = environment.documentationUrl;

  // -- async
  // TODO: not working properly, (true then false)
  // temporary fix
  // same in invitation.page.ts
  // probably related to delayWhen(([ s, id ]) => (s === ZefAuthState.Authorized && !id) ? timer(0) : EMPTY)
  // authorized$ = this._permission.authorize({
  //   only: [ Roles.Authorized ]
  // });
  authState$ = this._store.pipe(
    select(zefSelectAuthState)
  );
  authWholeState$ = this._store.pipe(select(selectAuthWholeState));
  authData$ = this._store.pipe(select(zefSelectAuthData));
  activeClientUser$ = this._userEntity.activeClientUser$;
  wsReconnectTranslations$ = this.translate$<WsReconnectTranslations>(WS_RECONNECT_FEATURE_NAME);
  wsReconnecting$ = combineLatest([
    this._store.pipe(select(selectZefWebsocketReconnecting)),
    this.authState$.pipe(map((s) => s === this.authStates.Authorized))
  ]).pipe(map(([ reconnecting, isAuthorized ]) => isAuthorized ? reconnecting : false));
  wsReconnectState$ = this.wsReconnecting$.pipe(
    map((reconnecting) => reconnecting ? '1' : '0')
  );
  wsReconnectShow$ = this.wsReconnecting$.pipe(
    audit((val) => interval(!!val ? 2000 : 3500))
  );
  // count projects and services for intercom
  projects$ = this.authWholeState$.pipe(
    filter((d) => !!d),
    filter(({ state, data }) => state === this.authStates.Authorized && !!data?.author && data?.author?.authorType !== 'BACKOFFICE'),
    distinctUntilChanged(),
    switchMap(() => this._projectEntity
      .listWithServiceStacks$()
      .pipe(
        filter((d) => !!d?.length),
        map((d) => d.reduce((obj, itm) => {
          obj.projects = obj.projects + 1;
          if (!!itm.serviceStacks?.length) {
            obj.services = obj.services + (itm.serviceStacks?.filter((d) => !d.isSystem).length || 0);

            obj.serviceTypes = uniq([
              ...obj.serviceTypes,
              ...itm.serviceStacks
                .filter((d) => !d.isSystem)
                .map((d) => `${d.serviceStackTypeInfo?.serviceStackTypeName} ${d.versionNumber}`)
            ]);

            if (obj.githubIntegration !== true) {
              obj.githubIntegration = itm.serviceStacks.some((d) => !!d.githubIntegration);
            }

            if (!!obj.gitlabIntegration !== true) {
              obj.gitlabIntegration = itm.serviceStacks.some((d) => !!d.gitlabIntegration);
            }

            if (!!obj.hasHttpRouting !== true) {
              obj.hasHttpRouting = itm.serviceStacks.some((d) => !!d.hasPublicHttpRoutingAccess);
            }

          }
          return obj;
        }, {
          projects: 0,
          services: 0,
          serviceTypes: [],
          githubIntegration: undefined,
          gitlabIntegration: undefined,
          hasHttpRouting: undefined
        })),
        distinctUntilChanged((a, b) => !!((a.projects === b.projects) && (a.services === b.services))),
        withLatestFrom(this.activeClientUser$),
        map(([
          {
            projects,
            services,
            serviceTypes,
            githubIntegration,
            gitlabIntegration,
            hasHttpRouting
          },
          company
        ]) => ({
          user_hash: company?.user?.intercomHash,
          company: {
            id: company?.client?.id,
            projects_count: projects,
            services_count: services,
            services_types: serviceTypes?.join(', '),
            has_github_integration: !!githubIntegration,
            has_gitlab_integration: !!gitlabIntegration,
            has_http_routing: !!hasHttpRouting
          }
        })),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        tap((data) => this._intercom.update(data))
      ))
  );

  // # State resolver
  state = this.$connect({
    authState: this.authState$,
    activeClientUser: this.activeClientUser$,
    wsReconnectTranslations: this.wsReconnectTranslations$,
    wsReconnectShow: this.wsReconnectShow$,
    wsReconnectState: this.wsReconnectState$,
    isCompact: this._theme.isCompact$
  });

  constructor(
    private _sanitizer: DomSanitizer,
    private _store: Store<AppState>,
    private _translationsService: ZefTranslationsService,
    private _iconRegistry: MatIconRegistry,
    private _intercom: Intercom,
    private _userEntity: UserEntity,
    private _projectEntity: ProjectEntity,
    private _theme: ThemeService,
    @Inject(DOCUMENT)
    private _document: Document
  ) {
    super();

    // add current release version to body tag
    this._document.body.classList.add('app-release-' + environment.appVersion);

    this._translationsService.setTranslations('general', { en });

    // custom icons
    CUSTOM_ICONS.forEach((name) => {
      this._iconRegistry.addSvgIcon(
        name,
        this._sanitizer.bypassSecurityTrustResourceUrl(
          `/assets/shared-assets/custom-icons/${name}.svg`
        )
      );
    });

    // add current theme as class
    this._theme.applyTheme();

  }

  ngOnInit() {

    super.ngOnInit();

    if (environment.enableIntercom === 'true') {

      // boot intercom
      this.authData$
        .pipe(
          takeUntil(this.onDestroy$),
          switchMap((authData) => this.activeClientUser$.pipe(
            filter((d) => !!d),
            map(({ user }) => ({ user, authData })))
          ),
          distinctUntilChanged((prev, curr) => prev.user?.intercomHash === curr.user?.intercomHash)
        )
        .subscribe(({ authData, user }) => {
          if (!!authData?.author && authData?.author?.authorType !== 'BACKOFFICE') {
            this._intercom.boot({
              app_id: environment.intercomAppId,
              user_hash: user?.intercomHash
            });
          }
        });

      // update intercom with user data
      this.authData$
        .pipe(
          filter((d) => !!d?.author && d?.author?.authorType !== 'BACKOFFICE'),
          switchMap(() => this.activeClientUser$.pipe(
            filter((d) => !!d),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            takeUntil(this.onDestroy$)
          )),
          delay(0),
          map(({ user, client }) => ({
            email: user.email,
            user_id: user.id,
            name: user.fullName,
            user_hash: user.intercomHash,
            company: {
              id: client.id,
              region: environment.region,
              name: client.accountName
            }
          })),
          distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        )
        .subscribe((data) => {
          this._intercom.update(data);
        });

      // update intercom with num of projects / services
      this.projects$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();

    }

  }

}
