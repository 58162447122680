export const FEATURE_NAME = '@zerops/zerops/feature/import-export-dialog';
export const DEFAULT_PROJECT_YAML = `project:
  # name your project
  name:
  # (optional) shortly describe the project
  description:
  # (optional) add tags as array
  tags:
# list all the services as array
services:
  # name your service
  - hostname:
    # select type, see full list of available types: docs.zerops.io/documentation/services/runtimes/nodejs.html
    # eg. nodejs@18, nginx@1.20, postgresql@12, mariadb@10.4,...
    type:
    # specify high-availability mode HA / NON_HA
    mode:

    # see full list of zerops.yml options:
    # docs.zerops.io/documentation/export-import/project-service-export-import.html#used-yaml-specification
`;
export const DEFAULT_SERVICE_YAML = `# list all the services as array
services:
  # name your service
  - hostname:
    # select type, see full list of available types: docs.zerops.io/documentation/services/runtimes/nodejs.html
    # eg. nodejs@18, nginx@1.20, postgresql@12, mariadb@10.4, ...
    type:
    # specify high-availability mode HA / NON_HA
    mode:

    # see full list of zerops.yml options:
    # docs.zerops.io/documentation/export-import/project-service-export-import.html#used-yaml-specification
`;
