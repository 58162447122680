import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ZefFormsModule } from '@zerops/zef/forms';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
// import { UserDataFormFieldModule } from '@zerops/zerops/common/user-data-form-field';
import { ZefPipesModule } from '@zerops/zef/pipes';
import {
  ObjectStorageServiceStackFormComponent
} from './object-storage-service-stack-form.component';
import {
  ObjectStorageServiceStackFormTranslationsModule
} from './object-storage-service-stack-form.translations';
import { HostnameFieldModule, DiskCapacityFieldModule } from '../../fields';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { MatIconModule } from '@angular/material/icon';
import { CodeFieldModule } from '@zerops/zui/code-field';

@NgModule({
  declarations: [ ObjectStorageServiceStackFormComponent ],
  imports: [
    ZefFormsModule,
    MaterialFormStackModule,
    MatCardModule,
    FormSectionModule,
    HostnameFieldModule,
    ObjectStorageServiceStackFormTranslationsModule,
    DiskCapacityFieldModule,
    ZuiDocsTextModule,
    ZefPipesModule,
    ZuiSeparatorComponent,
    MatIconModule,
    CodeFieldModule
    // UserDataFormFieldModule
  ],
  exports: [ ObjectStorageServiceStackFormComponent ]
})
export class ObjectStorageServiceStackFormModule {

}
