import { ServiceStack } from '@zerops/models/service-stack';

export type ProjectStatus = 'NEW' | 'CREATING' | 'ACTIVE' | 'DELETING' | 'DELETED' | 'FAILED';

export enum ProjectStatuses {
  New = 'NEW',
  Creating = 'CREATING',
  Active = 'ACTIVE',
  Deleting = 'DELETING',
  Stopping = 'STOPPING',
  Starting = 'STARTING',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Stopped = 'STOPPED'
}

export class Project {
  id: string;
  clientId: string;
  name: string;
  description: string;
  tagList: string[];
  status: ProjectStatuses;
  created: string;
  lastUpdate: string;
  logForwardingType: string;
  publicIpV4: string;
  sharedIpV4: string;
  publicIpV4PendingRequest: boolean;
  publicIpV4RequestDisabledTill: string;
  publicIpV6: string;
  maxCreditLimit: number;
}

export class ProjectWithServiceStacks extends Project {
  serviceStacks: ServiceStack[];
}

export class Log {
  expiry: string;
  signature: string;
  url: string;
}

export class LogData {
  id: string;
  appName: string;
  client: string;
  content: string;
  facility: number;
  facilityLabel: string;
  hostname: string;
  msgId: string;
  priority: number;
  procId: string;
  severity: number;
  severityLabel: string;
  structuredData: string;
  tag: string;
  timestamp: string;
  tlsPeer: string;
  version: number;
  _ws?: boolean;
}
