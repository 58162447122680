import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppVersion, AppVersionStatuses } from '@zerops/models/app-version';
import { ProcessStatuses } from '@zerops/models/process';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { BuildProcessStates, getPipelineState } from '@zerops/zui/build-state-steps';
// import { cleanupLog, closeLogStream, getLog } from '@zerops/zerops/feature/zlog';
import { AppState } from '@zerops/zerops/app';
import { Store } from '@ngrx/store';
import { ObservableInput } from 'observable-input';
import { combineLatest, Observable, Subject } from 'rxjs';
import { parse } from 'yaml';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { FEATURE_NAME } from './pipeline-detail.constant';
import { copyCodeDialogOpen } from '../copy-code-dialog';
import { buildCancel, ProcessEntity } from '@zerops/zerops/core/process-base';
import { PipelineDetailModes } from './pipeline-detail.model';
import { PipelineError } from '@zerops/models/error-backend';

const CREATED_BY_TYPE_PREFIX_MAP = {
  GITHUB: 'GitHub:',
  GITLAB: 'GitLab:',
  CLI: 'zcli:',
  USER: 'Zerops:'
};

@Component({
  selector: 'z-pipeline-detail',
  templateUrl: './pipeline-detail.feature.html',
  styleUrls: [ './pipeline-detail.feature.scss' ]
})
export class PipelineDetailFeature extends ZefReactiveComponent {

  // # Event Streams
  onOpenCopyCodeDialog$ = new Subject<void>();
  onBuildCancel$ = new Subject<string>();

  // # Data
  // -- sync
  buildProcessStates = BuildProcessStates;
  proccessStatuses = ProcessStatuses;
  appVersionStatuses = AppVersionStatuses;

  // - angular
  @ObservableInput()
  @Input('appVersion')
  appVersion$!: Observable<AppVersion>;

  @ObservableInput()
  @Input('pipelineErrors')
  pipelineErrors$!: Observable<PipelineError[]>;

  @ObservableInput()
  @Input('baseKey')
  baseKey$!: Observable<string>;

  @Input()
  mode: PipelineDetailModes = 'PIPELINE';

  @Input()
  maxWidthDetail: string;

  @Input()
  maxWidthLogs: string;

  @Input()
  hasBackbutton = false;

  @Input()
  backbuttonRoute: any[];

  @Input()
  logsScrollHeight = 'calc(85vh - 40px)'

  @Output()
  linkClicked = new EventEmitter<void>();

  @Output()
  modeChanged = new EventEmitter<PipelineDetailModes>();

  // -- async
  serviceStackId$ = this.onInit$.pipe(
    tap(() => this.mode = 'PIPELINE'),
    switchMap(() => this.appVersion$.pipe(
      filter((d) => !!d),
      map(({ serviceStackId }) => serviceStackId),
      distinctUntilChanged()
    ))
  );
  pipelineServiceStackIds$ = this.onInit$.pipe(
    switchMap(() => this.appVersion$.pipe(
      filter((d) => !!d),
      map((d) => ({
        build: d.build?.serviceStackId,
        prepare: d.prepareCustomRuntime?.serviceStackId
      }))
    ))
  );
  serviceStack$ = this.serviceStackId$.pipe(
    switchMap((id) => this._serviceStackEntity.entityById$(id)),
    filter((d) => !!d)
  );
  pipelineState$ = this.onInit$.pipe(
    switchMap(() => this.appVersion$.pipe(
      filter((d) => !!d),
      map((appVersion) => getPipelineState(appVersion))
    ))
  );
  emptyBaseKey$ = this.baseKey$.pipe(startWith(undefined as string));
  uniqBuildLogKey$ = this.pipelineServiceStackIds$.pipe(
    withLatestFrom(this.emptyBaseKey$),
    map(([ { build }, baseKey ]) => this._getKey(this._buildLogKey, baseKey, build))
  );
  uniqPrepareLogKey$ = this.pipelineServiceStackIds$.pipe(
    withLatestFrom(this.emptyBaseKey$),
    map(([ { prepare }, baseKey ]) => this._getKey(this._prepareLogKey, baseKey, prepare))
  );
  createdByTypePrefix$ = this.appVersion$.pipe(
    map((d) => CREATED_BY_TYPE_PREFIX_MAP[d?.createdByUser?.type])
  );
  buildConfig$ = this.appVersion$.pipe(
    map((d) => {

      const configParsed = d?.configContent
        ? parse(d?.configContent) as Record<string, Record<string, {
            run?: any;
            build: any;
          }>>
        : {};

      const key = Object.keys(configParsed);

      if (!key?.length) {
        console.warn(`Couldn't parse zerops.yml`);
        return {};
      }

      return configParsed[key[0]]
    })
  );
  processes$ = this._processEntity.list$();
  buildProcess$ = this.appVersion$.pipe(
    switchMap(({ id }) => this.processes$.pipe(
      filter((d) => !!d),
      map((processes) => processes.find((process) => process?.appVersion?.id === id))
    ))
  );
  buildLogParams$ = combineLatest([
    this.serviceStack$.pipe(
      filter((d) => !!d),
      map(({ projectId }) => projectId),
      distinctUntilChanged()
    ),
    this.pipelineServiceStackIds$,
    this.pipelineState$.pipe(
      filter((d) => d?.RUN_BUILD_COMMANDS === BuildProcessStates.Running
        || d?.RUN_BUILD_COMMANDS === BuildProcessStates.Finished
        || d?.RUN_BUILD_COMMANDS === BuildProcessStates.Failed),
      take(1)
    )
  ]).pipe(
    map(([ projectId, ids ]) => ({
      projectId,
      serviceStackId: ids.build,
      page: 5000
    }))
  );
  prepareLogParams$ = combineLatest([
    this.serviceStack$.pipe(
      filter((d) => !!d),
      map(({ projectId }) => projectId),
      distinctUntilChanged()
    ),
    this.pipelineServiceStackIds$,
    this.pipelineState$.pipe(
      filter((d) => d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Running
        || d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Finished
        || d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Failed),
      take(1)
    )
  ]).pipe(
    map(([ projectId, ids ]) => ({
      projectId,
      serviceStackId: ids.prepare,
      page: 5000
    }))
  );
  buildProcessState$ = this.pipelineState$.pipe(
    filter((d) => !!d),
    map((d) => d?.RUN_BUILD_COMMANDS)
  );
  prepareProcessState$ = this.pipelineState$.pipe(
    filter((d) => !!d),
    map((d) => d?.RUN_PREPARE_COMMANDS)
  );

   // # State resolver
   state = this.$connect({
    serviceStack: this.serviceStack$,
    appVersion: this.appVersion$,
    pipelineErrors: this.pipelineErrors$,
    createdByTypePrefix: this.createdByTypePrefix$,
    pipelineState: this.pipelineState$,
    uniqBuildLogKey: this.uniqBuildLogKey$,
    uniqPrepareLogKey: this.uniqPrepareLogKey$,
    buildProcess: this.buildProcess$,
    buildConfig: this.buildConfig$,
    buildLogParams: this.buildLogParams$,
    prepareLogParams: this.prepareLogParams$,
    buildProcessState: this.buildProcessState$,
    prepareProcessState: this.prepareProcessState$
  });

  private _buildLogKey = `${FEATURE_NAME}_BUILD`;
  private _prepareLogKey = `${FEATURE_NAME}_PREPARE`;

  // # Action Streams
  /*
  private _runBuildCommandsGetLogAction$ = this.pipelineState$.pipe(
    filter((d) => d?.RUN_BUILD_COMMANDS === BuildProcessStates.Running
      || d?.RUN_BUILD_COMMANDS === BuildProcessStates.Finished
      || d?.RUN_BUILD_COMMANDS === BuildProcessStates.Failed),
    take(1),
    withLatestFrom(this.appVersion$, this.uniqBuildLogKey$),
    map(([ d, appVersion, key ]) => getLog({
      key,
      projectId: appVersion.projectId,
      live: d.RUN_BUILD_COMMANDS === BuildProcessStates.Running,
      limit: 500,
      serviceStackId: appVersion.build.serviceStackId,
      minimumSeverity: 7
    }))
  );

  private _closeBuildCommandsStreamAction$ = this.pipelineState$.pipe(
    filter((d) => d?.RUN_BUILD_COMMANDS === BuildProcessStates.Failed
      || d?.RUN_BUILD_COMMANDS === BuildProcessStates.Finished),
    take(1),
    withLatestFrom(this.uniqBuildLogKey$),
    map(([ _, key ]) => closeLogStream(key))
  );

  private _closePrepareCommandsStreamAction$ = this.pipelineState$.pipe(
    filter((d) => d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Failed
      || d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Finished),
    take(1),
    withLatestFrom(this.uniqPrepareLogKey$),
    map(([ _, key ]) => closeLogStream(key))
  );

  private _runPrepareCommandsGetLogAction$ = this.pipelineState$.pipe(
    filter((d) => d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Running
      || d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Finished
      || d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Failed),
    take(1),
    withLatestFrom(this.appVersion$, this.uniqPrepareLogKey$),
    map(([ d, appVersion, key ]) => getLog({
      key,
      projectId: appVersion.projectId,
      live: d.RUN_PREPARE_COMMANDS === BuildProcessStates.Running,
      limit: 500,
      serviceStackId: appVersion.prepareCustomRuntime?.serviceStackId,
      minimumSeverity: 7
    }))
  );
  */

  private _openCopyCodeDialogAction$ = this.onOpenCopyCodeDialog$.pipe(
    withLatestFrom(this.appVersion$),
    map(([ _, appVersion ]) => copyCodeDialogOpen(appVersion?.configContent, 'Pipeline config (zerops.yml)'))
  );
  private _onBuildCancelAction$ = this.onBuildCancel$.pipe(
    map((id) => buildCancel({ id }))
  );

  constructor(
    private _serviceStackEntity: ServiceStackEntity,
    private _store: Store<AppState>,
    private _processEntity: ProcessEntity,
  ) {
    super();

    this.buildConfig$.subscribe();

    this.$dispatchActions([
      // this._runBuildCommandsGetLogAction$,
      // this._runPrepareCommandsGetLogAction$,
      // this._closeBuildCommandsStreamAction$,
      // this._closePrepareCommandsStreamAction$,
      this._openCopyCodeDialogAction$,
      this._onBuildCancelAction$
    ]);

    // have to be dispatched manually, dispatchActions
    // are unsubscribed before dispatch
    this.onDestroy$.pipe(
      take(1),
      withLatestFrom(this.uniqBuildLogKey$, this.uniqPrepareLogKey$)
    ).subscribe(([ _, buildKey, prepareKey ]) => {
      if (buildKey) {
        // this._store.dispatch(cleanupLog(buildKey));
      }
      if (prepareKey) {
        // this._store.dispatch(cleanupLog(prepareKey));
      }
    });
  }

  private _getKey(key: string, baseKey: string, id: string) {
    const base = `${key}_${id}`;
    if (!!baseKey) { return `${baseKey}_${base}`; }
    return base;
  }

}
