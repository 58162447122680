import { TrlogUrlParams } from './trlog.model';
import { HttpParams } from '@angular/common/http';
import subHours from 'date-fns/subHours';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import subMinutes from 'date-fns/subMinutes';
import pickBy from 'lodash-es/pickBy';
import identity from 'lodash-es/identity';
import { TrLogPeriods } from './trlog.constant';
import endOfDay from 'date-fns/endOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';

export const getTrlogEndpoint = (
  url: string,
  protocol: 'HTTP' | 'WS' = 'HTTP',
) => {
  // remove `GET ` from the beginning of the URL
  const normalizedUrl = url.substring(url.indexOf(' ') + 1);

  if (protocol === 'HTTP') {
    return `https://${normalizedUrl}`;
  } else {
    const urlParts = normalizedUrl.split('?');
    return `wss://${urlParts[0]}/stream?${urlParts[1]}`;
  }
}

export const addTrlogParamsToUrl = (
  url: string,
  params: TrlogUrlParams
) => {
  const queryParams = new HttpParams({ fromObject: pickBy({
    ...params,
    desc: params.desc ? params.desc : '0'
  }, identity) as any });
  return `${url}&${queryParams?.toString()}`;
}

export const periodSettings = {
  [TrLogPeriods.Today]: () => ({
    from: startOfDay(new Date()),
    till: new Date()
  }),
  [TrLogPeriods.Yesterday]: () => {
    const now = new Date();
    return {
      from: startOfDay(subDays(now, 1)),
      till: endOfDay(subDays(now, 1))
    };
  },
  [TrLogPeriods.Last15Minutes]: () => ({
    from: subMinutes(new Date(), 15),
    till: new Date()
  }),
  [TrLogPeriods.Last60Minutes]: () => ({
    from: subMinutes(new Date(), 60),
    till: new Date()
  }),
  [TrLogPeriods.Last7Hours]: () => ({
    from: subHours(new Date(), 7),
    till: new Date()
  }),
  [TrLogPeriods.Last24Hours]: () => ({
    from: subHours(new Date(), 24),
    till: new Date()
  }),
  [TrLogPeriods.Last7Days]: () => ({
    from: subDays(new Date(), 7),
    till: new Date()
  }),
  [TrLogPeriods.ThisWeek]: () => ({
    from: startOfWeek(new Date()),
    till: new Date()
  }),
  [TrLogPeriods.LastWeek]: () => {
    const now = new Date();
    return {
      from: startOfWeek(subDays(now, 7)),
      till: endOfWeek(subDays(now, 7))
    };
  }
};
