import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'zui-process-notification-duration',
  templateUrl: './process-notification-duration.component.html',
  styleUrls: [ './process-notification-duration.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessNotificationDurationComponent {
  @Input()
  type: 'QUEUED' | 'RUNNING' | 'FINISHED' | 'FAILED' | 'TIMER';

  @Input()
  start: string;

  @Input()
  end: string;

  @Input()
  hideText = false;

  @Input()
  hideTime = false;

  @Input()
  hideIcon = false;

  @Input()
  useShorthand = false;

  iconMap = {
    QUEUED: 'waiting',
    RUNNING: 'running',
    FINISHED: 'finished',
    FAILED: 'failed',
    TIMER: 'timer'
  }
}
