import { Component, ChangeDetectionStrategy, Input, ViewChildren, QueryList } from '@angular/core';
import { ServiceStackTypes } from '@zerops/models/service-stack';
import { ServiceStackHorizontalAutoscaling, ServiceStackVerticalAutoscaling } from '@zerops/models/settings';
import { CommonUserServiceStackFormTranslations } from './common-user-service-stack-form.translations';
import { ZefFormElementDirective } from '@zerops/zef/forms';

@Component({
  selector: 'z-common-user-service-stack-form',
  templateUrl: './common-user-service-stack-form.component.html',
  styleUrls: [ './common-user-service-stack-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonUserServiceStackFormComponent {

  serviceStackTypes = ServiceStackTypes;
  editorOptions = { language: 'ini' };

  @Input()
  state: any;

  @Input()
  projectName: string;

  @Input()
  isZen: boolean;

  @Input()
  serviceStackType: ServiceStackTypes;

  @Input()
  defaultHorizontalAutoscaling: ServiceStackHorizontalAutoscaling;

  @Input()
  defaultVerticalAutoscaling: ServiceStackVerticalAutoscaling;

  @Input()
  translations: CommonUserServiceStackFormTranslations;

  @ViewChildren(ZefFormElementDirective)
  formElementsRefs: QueryList<ZefFormElementDirective>;
}
